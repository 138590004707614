.wrapper {
  padding: 4px;
}
.selectFilter {
  :global {
    .css-1s2u09g-control {
      border: none;
      min-height: 18px;
    }

    .css-1pahdxg-control {
      border: none;
      min-height: 18px;
      box-shadow: none;
    }

    .css-1pndypt-Input {
      font: var(--font-400-14-18-R);
    }

    .css-14el2xx-placeholder {
      margin: 0;
      font: var(--font-400-14-18-R);
      color: var(--placeholder_color);
    }

    .css-6j8wv5-Input {
      input {
        font: var(--font-400-14-18-R);
      }
    }

    .css-1hb7zxy-IndicatorsContainer {
      position: relative;
      right: 8px;
      button {
        background: transparent;
        line-height: 0;
        font-size: 0;
        svg path {
          fill: var(--main_black);
          width: 11px;
          height: 11px;
        }
      }
    }

    .css-26l3qy-menu {
      max-height: 308px;
      margin: 0;
      padding-right: 6px;
      padding-top: 10px;
      width: 292px;
      top: calc(100% + 12px);
      left: -5px;
      z-index: 10;
    }

    .css-4ljt47-MenuList {
      padding-right: 10px;
      padding-top: 0;
      div {
        padding: 10px;
        padding-right: 0;
        border-bottom: 1px solid var(--separator_second);
      }

      div:first-child {
        padding-top: 0;
      }
      div:last-child {
        border-bottom: none;
      }
    }

    .css-9gakcf-option {
      color: var(--main_black);
      background-color: #deebff;
    }
  }
}

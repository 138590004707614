.container {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  position: relative;
  max-width: 896px;
}

.container_select {
  position: absolute;
}

.buttonPlusContainer {
  position: relative;
}

.add {
  width: 31px;
  height: 31px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2f5ff;
  border-radius: 30px;
}

.label {
  font: var(--font-500-15-18-M);
  color: var(--main_black);
  margin-top: 9px;
}

.errorLabel {
  color: var(--error);
}

.select {
  position: absolute;
  top: 43px;
  left: 0;
}

.selectedOptions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  max-width: 640px;
  font: var(--font-400-15-18-R);
  color: var(--main_black);
  text-decoration: underline;
  text-decoration-skip-ink: none;
}

.option {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 171px;
  margin-right: 20px;
  gap: 8px;
}

.tooltipText {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  span {
    display: inline;
    font: var(--font-400-15-18-R) !important;
  }
}

.footerWrapper {
  display: flex;
  align-items: center;
}

.input {
  width: 79px;
  height: 38px;
  margin-right: 18px;
  padding: 10px 0 10px 10px;
  background: var(--white);
  border-radius: 8px;
  font: var(--font-400-15-18-R);
  color: var(--text);
}

.listItemTag {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 270px;
  min-height: 224px;
  padding: 0 21px;
  margin-left: -1px;
}

.titleBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  svg {
    width: 26px;
    height: 26px;
    fill: var(--input_background);
    border: 0.5px solid var(--main_black);
    border-radius: 50%;
    rect {
      stroke: var(--input_background);
    }
    path {
      fill: var(--main_black);
    }
  }
}

.titleBlockShow {
  min-width: fit-content;
  border-bottom: 1px solid #dfddff;
}

.titleAndAdd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.titleWrapper {
  position: relative;
  cursor: pointer;
  margin: 18px 0 10px 0;

  h4 {
    max-width: 197.48px;
  }
}

.titleWrapperShow {
  h4 {
    max-width: 665px;
  }
}

.title {
  padding: 4px 8px 5px;
  font: var(--font-400-14-18-R);
  background-color: rgba(231, 127, 240, 0.3);
  border-radius: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.titleShowAll {
  padding: 4px 1px 10px;
  font-size: 17px;
  font-weight: 700;
  background-color: #ffffff;
}

.addButtonWrapper {
  position: relative;
  margin: 0 20px 20px 0;
}

.addButtonShow {
  margin: 0 30px 30px 0;
}

.buttonPlusMiddle {
  position: absolute;
  display: none;
  background-color: transparent;
  font-size: 0;
  line-height: 0;
}

.buttonCancel {
  position: absolute;
  background-color: #ffffff;
  margin: 5px 0 0 15px;
  font-size: 18px;
}

.listItemTag:hover .buttonPlusMiddle {
  display: block;
}

.listBlock {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  gap: 5px;
  width: 100%;

  h4 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 226.59px;
  }
}

.listBlockShow {
  margin: 15px 0 0 3px;

  h4 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 228px;
  }
}

.inputWidth1 {
  width: 240px;
  margin: -10px 0 0 -10px;
}

.inputWidth2 {
  width: 240px;
  margin: -10px 0 0 -220px;
}

.showAll {
  position: absolute;
  min-width: 374px;
  width: fit-content;
  height: 509px;
  left: 50%;
  transform: translateX(-50%);
  top: 45px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 0px 18px 2px rgba(128, 125, 174, 0.33);
  z-index: 9;
}

.showAll ul::before {
  opacity: 0;
}

.listTag {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.contextMenu {
  margin: 10px 0 0 -60px;
}

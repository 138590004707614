// https://stackoverflow.com/questions/60849448/how-can-i-change-the-styles-of-the-react-toastify-popup-message

.Toastify__toast-container--top-center {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.Toastify__toast-container {
  width: auto;
  border-radius: 7px !important;
  font: var(--font-500-15-18-R);
}

.Toastify__toast-icon {
  display: none !important;
}

.Toastify__toast > button > svg {
  display: none;
}

//success
.Toastify__toast--success {
  position: relative;
  padding: 16px 40px 16px 18px !important;
  background: #3e9fbe !important;
  color: #fff;
}

.Toastify__toast--success::after {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  right: 18px;
  top: 20px;
  background: url('../assets/Like.svg') center 0 no-repeat;
}

//error
.Toastify__toast--error {
  position: relative;
  padding: 16px 40px 16px 18px !important;
  border: 1px solid #f23333;
  background: #ffeded !important;
  color: #434343 !important;
}

.Toastify__toast--error::after {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  right: 18px;
  top: 20px;
  background: url('../assets/Sad.svg') center 0 no-repeat;
}

//warning
.Toastify__toast--warning {
  border-radius: 6px !important;
  background: #fffadf !important;
  box-shadow: 0px 0px 18px 2px #807dae54;
  color: #434343 !important;
}

// .Toastify__toast--warning::before {
//   position: relative;
//   z-index: 100000;
//   left: 12px;
//   top: 6px;
// }
// .Toastify__toast--warning::after {
//   position: absolute;
//   color: #e78326;
//   font-size: 15px;
//   font-weight: 700;
//   left: 265px;
//   padding-top: 14px !important;
// }

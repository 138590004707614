.container {
  display: flex;
  align-items: center;
  width: 397px;
}

.selectWrapper {
  position: relative;
}

.label {
  white-space: nowrap;
  margin-top: 4px;
  margin-right: 10px;
  height: 20px;
  font: var(--font-500-15-18-M);
  display: flex;
  gap: 3px;
}

.add {
  width: 31px;
  height: 31px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2f5ff;
  border-radius: 30px;
}

.select {
  width: 412px;
  padding: 10px;
  background: #ffffff;
  box-shadow: 0 0 10px 2px rgba(128, 125, 174, 0.1) !important;
  border-radius: 6px !important;
  position: absolute;
  top: 24px;
  left: -40px;
}

.select_edit {
  width: 412px;
  padding: 10px;
  background: #ffffff;
  box-shadow: 0 0 10px 2px rgba(128, 125, 174, 0.1) !important;
  border-radius: 6px !important;
  position: absolute;
  top: 4px;
  left: 0;
}

.editWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  a {
    min-width: fit-content;
  }
}

.editPlaylistWrapper {
  display: flex;
  align-items: center;
  a {
    min-width: fit-content;
  }
}

.optionDescription {
  font: var(--font-400-15-18-R);
  color: var(--main_black);
  text-decoration: underline;
  text-decoration-skip-ink: none;
  max-width: 181px;
  span {
    display: block;
    font: var(--font-400-15-18-R);
  }
}

.input {
  width: 100%;
  font: var(--font-400-14-18-R);
  padding: 8px 10px;
  border-radius: 4px;
  background-color: #f4f5f8;
  color: var(--main_black);
  &::placeholder {
    color: var(--text);
  }
}

.errorLabel {
  color: var(--error);
}

.switchBlock {
  display: flex;
  flex-wrap: nowrap;
  gap: 6px;
  justify-content: flex-start;
  margin-block: 25px 18px;
}

.switchActive {
  margin-top: 15px;
}

.switch {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;

  span {
    margin-right: 0;
    font: var(--font-400-15-18-R);
  }
}

.inputHidden {
  display: none;
}

.input {
  display: inline-block;
  text-align: right;
  font: var(--font-400-14-18-R);
  background-color: var(--button_delete);
  padding: 6px 12px;
  border-radius: 4px;
  max-width: 40px;
}

input[type='number'] {
  appearance: none;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &:hover,
  &:focus {
    appearance: none;
    -moz-appearance: textfield;
  }
}

.button {
  border: 0;
  border-radius: 30px;
  width: 335px;
  height: 50px;
  font: var(--font-500-16-19-R);
  letter-spacing: 0;
  color: #fff;
  background: var(--button_primary);
  cursor: pointer;

  &:hover {
    background: var(--button_primary-hover);
  }

  &:active {
    background: var(--button_primary-active);
  }

  &:disabled {
    background-color: var(--button_primary-disabled);
  }
}

.container {
  width: 1200px;
  display: flex;
  align-items: center;
}

.title {
  width: 158px;
  font: var(--font-500-15-18-M);
  color: var(--main_black);
  margin-right: 52px;
}

.errorTitle {
  color: var(--error);
}

.text {
  font: var(--font-400-14-18-R);
  color: var(--text);
}

.name {
  width: 498px;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  color: var(--main_black);
}

.typeElement {
  width: 150px;
  margin-right: 54px;
}

.feedType {
  width: 175px;
  margin-right: 54px;
}

.feedId {
  font: var(--font-400-14-18-R);
  color: var(--text);
  width: 62px;
}

.tooltip {
  span {
    color: var(--text);
  }
}

.wrapper {
  display: flex;
  gap: 46px;
}

.clipWrapper {
  display: flex;
}

.content {
  display: flex;
  gap: 28px;
  align-items: flex-start;
  padding-top: 8px;
}

.contentClip {
  display: flex;
  flex-direction: row-reverse;
  justify-content: left;
  gap: 28px;
  padding-top: 8px;
  margin-top: -24px;
}

.contentPlaylist {
  display: flex;
  gap: 85px;
  align-items: flex-start;
  padding-top: 8px;
}

.count {
  display: flex;
  align-items: flex-start;
  font: var(--font-500-15-18-M);
  gap: 6px;
  span {
    color: #7d7d7d;
  }
}

.entity {
  color: #7d7d7d;
  span {
    display: block;
    width: 60px;
    font: var(--font-400-15-18-R);
    white-space: nowrap;
  }
}

.sku {
  span {
    color: #7d7d7d;
    font: var(--font-400-15-18-R);
  }
}

.date {
  min-width: 196px;
  font: var(--font-500-15-18-R);
}

.duration {
  font: var(--font-500-15-18-R);
}

.dateEvents {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.separator {
  margin-bottom: 0;
}

.headerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
}

.blockWrapper {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;
}

.gridItem1 {
  width: 661px;
  grid-area: 1 / 1 / 4 / 3;
}

.autoName {
  word-spacing: 6px;
}

.inputTextArea {
  width: 601px;
  textarea {
    width: 601px;
  }
}

.tags {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
  flex-wrap: wrap;
  margin-top: 24px;
  h3 {
    font: var(--font-500-15-18-M);
    color: var(--main_black);
  }
  .addTag {
    width: 31px;
    height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: var(--main_background);
  }
  .tagsBlock {
    display: flex;
    gap: 8px;
    padding: 10px;
    border: 1px solid var(--input_border);
    border-radius: 10px;
  }
}

.link {
  font: var(--font-500-14-18-M);
  color: var(--link_hover);
  text-decoration: underline;
}

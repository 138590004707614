.button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border-radius: 30px;
  font: var(--font-400-13-17-R);
}

.icon {
  display: flex;
  margin-right: 4px;
}

.variant-normal {
  color: #ffffff;
  background: #8179fe;
}

.variant-reverse {
  border: 1px solid #8179fe;
  color: #8179fe;
}

.size-normal {
  height: 36px;
  padding: 9 16px;
}

.size-small {
  padding: 6px 10px;
}

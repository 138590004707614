.button {
  padding: 9px 16px;
  background: #8179fe;
  border-radius: 30px;
  color: var(--white);
  display: flex;
  gap: 6px;
  justify-content: center;

  &:disabled {
    background-color: var(--button_primary-disabled);
  }
}

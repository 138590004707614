.wrapper {
  position: relative;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.label {
  margin-bottom: 8px;
  font: var(--font-500-15-18-M);
  color: var(--main_black);
  display: flex;
  gap: 3px;
  white-space: nowrap;
}

.errorLabel {
  color: var(--error);
}

.hidden {
  opacity: 0;
  height: 0;
  width: 0;
  line-height: 0;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.img {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f4f5f8;
  border-radius: 4px;
  cursor: pointer;
}

.primaryImage {
  width: 100px;
  height: 100px;
  left: 68px;
  object-fit: cover;
}

.secondaryImage {
  width: 100px;
  height: 100px;
  left: 68px;
  object-fit: cover;
}

.primaryBanner {
  width: 212px;
  height: 74px;
  left: 180px; // одни стили для баннера и кнопки? TODO: разделить?
  object-fit: cover;
}

.secondaryBanner {
  width: 179px;
  height: 100px;
  left: 146px;
  object-fit: cover;
}

.wrapper {
  display: flex;
  align-items: flex-end;
}

.trashButton {
  width: 26px;
  height: 26px;
  padding-top: 2px;
  background: #ffeded;
  border-radius: 30px;
  position: absolute;
  bottom: 6px;
}

.compilationPrimaryAndSecondaryBanner {
  width: 212px;
  height: 74px;
  object-fit: cover;
}

.compilationBannerTrash {
  left: 181px;
}

@font-face {
  font-family: 'Raleway-Bold';
  src: url('./Raleway-Bold.woff2');
}

@font-face {
  font-family: 'Raleway-SemiBold';
  src: url('./Raleway-SemiBold.woff2');
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url('./Roboto-Medium.woff2');
}

@font-face {
  font-family: 'Roboto-Regular', sans-serif;
  src: url('./Roboto-Regular.woff2');
}

@font-face {
  font-family: 'Proxima Nova Semibold';
  src: url('./ProximaNova/ProximaNova-Semibold.eot');
  src: url('./ProximaNova/ProximaNova-Semibold.eot?#iefix')
      format('embedded-opentype'),
    url('./ProximaNova/ProximaNova-Semibold.woff') format('woff'),
    url('./ProximaNova/ProximaNova-Semibold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Condensed-Medium';
  font-weight: 400;
  src: url('./RobotoCondensed-Medium.ttf');
  font-display: block;
}

//ROBOTO
@font-face {
  font-family: 'Roboto Flex';
  src: url('./RobotoFlex-Regular.woff2') format('woff2') tech('variations');
  font-weight: 100 1000;
  font-stretch: 75 100;
  font-display: block;
}

.contentWrapper {
  margin-top: 18px;
}

.mainParametersWrapper {
  display: flex;
  align-items: center;
}

.featureMargin {
  margin-right: 42px;
}

.date {
  margin-right: 42px;
}

.inputDate {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 42px;

  h3 {
    font: var(--font-500-15-18-M);
  }

  input {
    max-width: 150px;
  }
}

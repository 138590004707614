.navbar {
  width: 1340px;
  outline: none;
  margin: 24px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #8179fe;
  box-shadow: 0 0 20px 2px rgba(120, 120, 120, 0.08);
  border-radius: 36px;
}

.wrapper {
  display: flex;
  align-items: center;
}

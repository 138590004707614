.listItemTheme {
  position: relative;
  cursor: pointer;
  max-height: 27px;
  align-self: flex-start;
}

.listItemThemeName {
  color: var(--main_black);
  font: var(--font-400-14-18-R);
}

.contextMenu {
  margin: -5px 0 0 -55px;
}

.inputWidth3 {
  width: 240px;
  margin-left: -10px;
  margin-top: -20px;
}

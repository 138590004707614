.imageBlock {
  position: relative;
  font-size: 0;
  line-height: 0;
}

.button_item {
  padding: 6px;
  font-size: 0;
  line-height: 0;
  border-radius: 50%;
  position: absolute;
  bottom: 6px;
  right: 6px;
  svg {
    height: 12px;
    width: 12px;
  }
}

.blockFeed {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.blockHumanity {
  gap: 0;
}

.blockItemFeed {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  width: 90px;
}

.blockItemHumanity {
  width: 78px;
}

.blockItemMarked {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: calc(100% + 10px);
    height: 1px;
    bottom: 0px;
    left: -5px;
    background: var(--main_purple);
  }
}

.blockItemStart {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 1px;
    height: 8px;
    bottom: 0px;
    left: -5px;
    background: var(--main_purple);
  }
}

.blockItemFinish {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: 1px;
    height: 8px;
    bottom: 0px;
    right: -5px;
    background: var(--main_purple);
  }
}

.image {
  width: 90px;
  height: 90px;
}

.imagePeople {
  width: 62px;
  height: 62px;
  align-self: center;
  border-radius: 50%;
  margin: 8px;
}

.titleItemFeed,
.titleItemFeedPeople {
  max-height: 36px;
  span {
    font: var(--font-400-14-18-R);
  }
}

.titleItemFeed {
  font: var(--font-400-14-18-R);
  span {
    display: block;
    width: 90px;
    white-space: nowrap;
  }
}

.titleItemFeedPeople {
  font: var(--font-400-14-18-R);
  text-align: center;
  span {
    padding-inline: 8px;
  }
}

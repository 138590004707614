.attributeWrapper {
  position: relative;
  button {
    position: relative;
    top: -4px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 6px;
}

.input {
  font: var(--font-400-14-18-R);
  color: var(--main_black);
  padding: 12px 10px;
  border: 1px solid var(--separator_second);
  border-radius: 4px;
  width: 100%;

  &::placeholder {
    color: var(--placeholder_color);
  }
}

.rangeInput {
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;
  align-items: center;
}

.switch {
  h3 {
    font: var(--font-500-13-17-M);
  }
}

.containerWrapper {
  display: flex;
  flex-direction: column;
  padding: 14px 19px 30px 30px;
  background-color: var(--white);
  box-shadow: 0 0 20px 2px rgba(120, 120, 120, 0.08);
  border-radius: 30px;
}

.blockTitle {
  margin-bottom: 14px;
  font: var(--font-700-17-24-Ral-B);
  color: var(--main_black);
}

.error {
  color: var(--error);
}

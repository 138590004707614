.container {
  width: 1340px;
  margin: 0 auto 18px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

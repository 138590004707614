.container {
  display: flex;
  flex-direction: column;
}

.label {
  margin-bottom: 10px;
  font: var(--font-500-15-18-M);
  color: var(--main_black);
  display: flex;
  gap: 3px;
}

.errorLabel {
  color: var(--error);
}

.textarea {
  width: 760px;
  height: 98px;
  padding: 14px;
  background: #f4f5f8;
  border-radius: 4px;
  font: var(--font-400-14-18-R);
  color: var(--main_black);
  resize: none;

  &::placeholder {
    font: var(--font-400-14-18-R);
    color: #7d7d7d;
  }
}

.errorTextarea {
  border: 1px solid var(--error);

  &::placeholder {
    color: var(--error);
  }
}

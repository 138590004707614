.container {
  height: 36px;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 30px;
}

.desc {
  padding-left: 5px;
  font: var(--font-400-15-18-R);
}

.normal {
  background: var(--main_purple);
  color: var(--white);
  &:hover {
    color: var(--white);
  }

  &.disabled {
    color: var(--white);
    background: #929292;
    border: 1px solid #929292;
    pointer-events: none;
    > svg rect {
      stroke: var(--white);
    }
    > svg path {
      fill: var(--white);
    }
    &:hover {
      cursor: default;
    }
  }
}

.reverse {
  border: 1px solid var(--main_purple);
  color: var(--main_purple);
  background: transparent;

  > svg rect {
    stroke: var(--main_purple);
  }
  > svg path {
    fill: var(--main_purple);
  }

  &.disabled {
    color: var(--white);
    background: #929292;
    border: 1px solid #929292;
    pointer-events: none;
    > svg rect {
      stroke: var(--white);
    }
    > svg path {
      fill: var(--white);
    }
    &:hover {
      cursor: default;
    }
  }
}

//select
.errorSelect {
  > button {
    border: 1px solid var(--error);
    color: var(--error);
  }
}

.selectWrapper {
  position: relative;
}

.select {
  border: 1px solid #e8e8e8;
  padding: 5px 12px 5px 10px;
  background: #ffffff;
  border-radius: 6px;
  height: 44px;
  display: flex;
  justify-content: space-between;
  color: #434343;
  font: var(--font-500-13-17-R);
  align-items: center;
  width: 100%;
}

.selectInner {
  text-align: left;
}

.selectDate {
  font: var(--font-400-13-17-R);
  display: block;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.dateDropdown {
  position: absolute;
  z-index: 1;
  top: calc(100% + 8px);
  left: 0;
  display: flex;
  background: white;
  padding: 14px 10px 16px;
  border-radius: 6px;
  box-shadow: 0px 0px 18px 2px rgba(128, 125, 174, 0.19);
}

.dateSelectOpen {
  @extend .dateDropdown;
  display: flex;
}

.dateSelectOpenNoBorder {
  @extend .dateDropdown;
  display: flex;
  padding: 0;
  box-shadow: none;
}

.dateSelect {
  @extend .dateDropdown;
  display: none;
}

.iconArrowDown {
  transform: rotate(180deg);
}

.error {
  background-color: #fffadf;
  border-radius: 6px;
  box-shadow: 0px 0px 18px 2px rgba(128, 125, 174, 0.19);
  padding: 16px 16px 16px 12px;
  min-width: 230px;
}

.wrapper {
  display: flex;
  align-items: center;
}

.label {
  margin-right: 8px;
  font: var(--font-500-15-18-M);
  color: #434343;
}

.container {
  display: flex;
  align-items: center;
  width: 37px;
  height: 20px;
  background: #dcdcdc;
  border-radius: 30px;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

.toggle {
  width: 18px;
  height: 18px;
  margin: 0 1px 0 1px;
  background: #ffffff;
  border-radius: 50%;
  transition: margin-left 0.2s ease-in-out;
}

.selected {
  background: #8179fe;
}

.toggleSelected {
  margin-left: 50%;
}

.attributeItem {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.content {
  width: 292px;
  padding: 12px;
  position: relative;
  border: 1px solid var(--separator_second);
  border-radius: 4px;
}

.contentWithSwitch {
  border: none;
  border-top: 1px solid var(--separator_second);
  border-bottom: 1px solid var(--separator_second);
  border-radius: 0;
  padding: 0;
  padding-block: 14px;
}

.title {
  font: var(--font-500-14-18-M);
  background-color: #fff;
  padding-inline: 2px;
  position: absolute;
  top: -9px;
  left: 10px;
  z-index: 9;
}

.titleSwitch {
  position: relative;
  top: 0;
  left: 0;
}

.deleteAttribute {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  background: var(--button_delete);
  border-radius: 50%;
  cursor: pointer;
  svg {
    width: 13px;
    height: 13.7px;
    path {
      fill: var(--main_black);
    }
  }

  &:hover {
    background: var(--button_delete-bg);
    svg path {
      fill: var(--button_delete-text);
    }
  }
}

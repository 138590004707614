.select {
  width: 412px;
  padding: 10px;
  background: #ffffff;
  box-shadow: 0 0 10px 2px rgba(128, 125, 174, 0.1);
  border-radius: 6px;
  position: absolute;
  top: 46px;
  left: -230px;
  right: 0;
  z-index: 10;
}

.input {
  width: 100%;
  font: var(--font-400-14-18-R);
  padding: 8px 10px;
  border-radius: 4px;
  background-color: #f4f5f8;
  color: var(--main_black);
  &::placeholder {
    color: var(--text);
  }
}

.left {
  left: 0;
}

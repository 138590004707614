.virtuoso {
  width: 1358px;
  height: calc(100vh - 322px) !important;
}
.scrollToTopButton {
  position: fixed;
  left: 50%;
  top: calc(100vh - 20%);

  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;

  margin-left: 625px;

  background: #8179fe;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
}

.expand {
  height: calc(100vh - 146px) !important;
}

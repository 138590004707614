.wrapper {
  border-top: 1px solid #e8e8e8;
}

.inputWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block: 20px;
  border-bottom: 1px solid #e8e8e8;
  position: relative;
  margin-left: 10px;
}

.button {
  margin-block: 20px;
  background-color: transparent;
}

.dnd {
  position: absolute;
  left: -28px;
}

.contentContainer {
  width: 1340px;
  margin: 18px auto 0 auto;
  position: relative;
}

.buttonsColumn {
  width: 90px;
  text-align: right;
}

.titleWrapper {
  margin-bottom: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttons {
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 18px;
}

.titleColumn {
  width: 364px;
}

.imgColumn {
  width: 63px;
}

.rightOwnerColumn {
  width: 128px;
}

.playlistCountColumn {
  width: 128px;
}

.clipCountColumn {
  width: 128px;
}

.createdColumn {
  width: 99px;
}

.contentItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1340px;
  height: 69px;
  margin-bottom: 10px !important;
  background: var(--white);
  box-shadow: 0 0 20px 2px rgba(120, 120, 120, 0.08);
  border-radius: 12px;

  &:last-child {
    margin: 0;
  }
}

.titleDesc {
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.footer {
  margin-top: 24px;
  position: absolute;
  bottom: 25px;
  right: 40%;
  display: flex;
  justify-content: center;
}

.copyButton {
  background-color: transparent;
}

.copyButton:disabled {
  cursor: default;
  > svg > path {
    fill: #7d7d7d;
  }
}

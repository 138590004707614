.listItem {
  position: relative;
  display: grid;
  grid-template-columns: 400px 184px 222px 222px 201px 1fr; // те же значения что и в sectionListHeader
  padding: 8px 19px;
  box-shadow: 0px 0px 20px 2px #78787814;
  border-radius: 12px;
  background: #ffffff;
  color: #434343;

  > div {
    display: flex;
    align-items: center;
  }
}

.listItem__auto {
  @extend .listItem;
  background: #fffadf;
}

.listItem__info {
  @extend .listItem;
  background: #fef0a3;
}

.listItemControls {
  display: flex;
  justify-content: space-between;
}

.dndButton {
  position: absolute;
  left: calc(-20px - 10px);
  top: calc(50% - (8px / 2));
  display: flex;
  background: transparent;
  cursor: grab;
}

.dndButtonActive {
  @extend .dndButton;
  left: -42px;
  top: calc(50% - 22px);
}

.title {
  font: var(--font-500-14-18-M);
}

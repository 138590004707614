.container {
  position: relative;

  display: flex;
  flex-direction: column;
}

.label {
  margin-bottom: 10px;
  font: var(--font-500-15-18-M);
  color: var(--main_black);
  display: flex;
  gap: 3px;
}

.errorLabel {
  color: var(--error);
}

.disabledLabel {
  color: var(--disabled);
}

.input {
  width: 442px;
  padding: 14px;
  background: var(--input_background);
  border-radius: 4px;
  font: var(--font-400-14-18-R);
  color: var(--main_black);

  &::placeholder {
    font: var(--font-400-14-18-R);
    color: var(--placeholder_color);
  }
}

.inputWithElementRight {
  padding-right: 34px;
}

.errorInput {
  border: 1px solid var(--error);

  &::placeholder {
    color: var(--error);
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--main_black);
  -webkit-box-shadow: 0 0 0px 40rem var(--input_background) inset;
}

.elementRight {
  position: absolute;
  bottom: 14px;
  right: 14px;
}

.wrapper {
  width: 661px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.highContent {
  display: flex;
  gap: 26px;
}

.selectAge {
  width: 248px;
}

.selectLangauge {
  width: 170px;
  span {
    margin-right: 7px;
  }
}

.dropdown {
  height: 120px;
  overflow: auto;
}

.selectTerritory {
  width: 212px;
  display: flex;
}

.input {
  max-width: 103px;
  padding: 14px;
  background: #f4f5f8;
  border-radius: 4px;
  font: var(--font-400-14-18-R);
  color: var(--main_black);
  position: relative;

  &::placeholder {
    font: var(--font-400-14-18-R);
    color: var(--text);
  }

  :global {
    .react-datepicker__day-names {
      display: flex;
    }

    .react-datepicker-popper[data-placement^='bottom'],
    .react-datepicker-popper[data-placement^='top'] {
      z-index: 11 !important;
    }
  }
}

.errorInput {
  border: 1px solid var(--error);
}

.errorInput::placeholder {
  color: var(--error);
}

.dateBlock {
  display: flex;
  flex-direction: column;
}

.container {
  position: relative;
  margin-bottom: 22px;
}

.addAttribute {
  font: var(--font-500-14-18-M);
  color: var(--link_hover);
  background-color: transparent;
}

.select {
  position: absolute;
  top: 26px;
  z-index: 999;
  div {
    max-width: 350px;
  }
}

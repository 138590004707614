.frames {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 14px 0;
  background-color: var(--white);
  border-radius: 4px;
  box-shadow: 0 0 20px 2px rgba(120, 120, 120, 0.08);
  position: absolute;
  width: 283px;
  top: 30px;
  left: 8%;
  z-index: 10;

  button {
    text-align: left;
    background-color: transparent;
    font: var(--font-400-14-18-R);
    padding-bottom: 10px;
    border-bottom: 1px solid #e8e8e8;
    &:last-child {
      border-bottom: none;
    }
  }

  .option_button:last-child {
    color: #f23333;
  }
}

.link {
  color: var(--main-black);
  &:hover {
    color: var(--main-black);
  }
}

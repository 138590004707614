.container {
  display: none;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  left: 50%;
  width: 515px;
  height: 100px;
  border-radius: 28px 28px 0px 0px;
  background: var(--main-fields, #f4f5f8);
  box-shadow: 0px 0px 10px 0px rgba(91, 87, 146, 0.3);
  transform: translate(-50%, 0);
  padding-top: 20px;

  .closeButton {
    display: flex;
    background: transparent;
    cursor: pointer;
    z-index: 10;
    font-size: 0;
    line-height: 0;
    width: 18px;
    height: 18px;
    position: absolute;
    right: 20px;
    top: 18px;
    svg {
      width: 18px;
      height: 18px;
    }
  }

  .naming {
    display: flex;
    gap: 8px;
    padding: 0 80px;
    align-items: center;
    height: fit-content;

    .playButton {
      border: none;
      background: transparent;
      width: fit-content;
      height: 30px;
    }

    .title {
      color: var(--main_black, #434343);
      font: var(--font-400-15-18-R);
    }
  }

  .progressBar {
    display: flex;
    gap: 12px;
    margin: 0 auto;
    margin-top: 12px;
    align-items: center;

    .time {
      color: var(--main_black, #434343);
      font: var(--font-400-14-18-R);
    }

    input[type='range'] {
      -webkit-appearance: none;
      appearance: none;
      width: 340px;
      cursor: pointer;
      outline: none;
      animation-duration: 0.5s;
      border-radius: 3px;
      border: 1px solid var(--button_primary-disabled, #b5b5b5);
      background: var(--main-fields, #f4f5f8);
      height: 5px;
    }

    /* Thumb: webkit */
    input[type='range']::-webkit-slider-thumb {
      /* removing default appearance */
      -webkit-appearance: none;
      appearance: none;
      /* creating a custom design */
      height: 11px;
      width: 11px;
      background-color: #8179fe;
      border-radius: 50%;
      border: none;

      /* box-shadow: -407px 0 0 400px #f50; emove this line */
      transition: 0.2s ease-in-out;
    }

    /* Thumb: Firefox */
    input[type='range']::-moz-range-thumb {
      width: 11px;
      height: 11px;
      background-color: #8179fe;
      border-radius: 50%;
      border: none;

      /* box-shadow: -407px 0 0 400px #f50; emove this line */
      transition: 0.2s ease-in-out;
    }
  }
}

.open {
  display: flex;
}

.wrapper {
  width: 320px;
  height: 262px;
  background-color: #fff;
  border-radius: 16px;
  position: relative;
  border: 1px solid #eee6fd;
}

.typeTag {
  width: 1340px;
  min-height: 278px;
  border: 1px solid #eee6fd;
}

.typeTag > div::before,
.typeTag > div::after,
.typeTag ul:before,
.typeTag ul::after {
  display: block;
  position: absolute;
  top: 72px;
  margin-left: -21px;
  content: '';
  width: 1px;
  height: 68%;
  z-index: 8;
  background-color: var(--separator_second);
}

.typeTag > div::before {
  left: 289px;
}

.typeTag > div::after {
  left: 558px;
}

.typeTag ul::before {
  left: 827px;
}

.typeTag ul::after {
  left: 1096px;
}

.typeTagExtended {
  width: 1340px;
  min-height: 502px;
  border: 1px solid #eee6fd;
}

.typeTagExtended > div::before,
.typeTagExtended > div::after,
.typeTagExtended ul::before,
.typeTagExtended ul::after {
  display: block;
  position: absolute;
  top: 72px;
  margin-left: -21px;
  content: '';
  width: 1px;
  height: 38%;
  background-color: var(--separator_second);
}

.typeTagExtended > ul::after {
  left: 690px;
  top: -370px;
  height: 1300px;
  transform: rotate(90deg);
}

.titleBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 13px 16px;
  border-radius: 16px 16px 0 0;
  background: rgba(140, 90, 240, 0.15);
  gap: 8px;

  svg {
    width: 26px;
    height: 26px;
    fill: var(--input_background);
    border: 0.5px solid var(--main_black);
    border-radius: 50%;
    rect {
      stroke: var(--input_background);
    }
    path {
      fill: var(--main_black);
    }
  }
}

.titleAndAdd {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.titleWrapper {
  position: relative;
  cursor: pointer;
}

.parentName {
  font: var(--font-400-14-18-R);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
  min-width: 70px;
}

.input {
  color: var(--main_black);
  font: var(--font-500-16-16-M);
  &::placeholder {
    color: var(--placeholder_color);
    font: var(--font-400-16-16-R);
  }
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
}

.inputTag {
  color: var(--main_black);
  font: var(--font-500-16-16-M);
  &::placeholder {
    color: var(--placeholder_color);
    font: var(--font-400-16-16-R);
  }
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 1075px;
}

.addButtonWrapper {
  position: relative;
}

.buttonPlusMiddle {
  background-color: transparent;
  font-size: 0;
  line-height: 0;
}

.listBlock {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 5px;
  width: 100%;

  h4 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 260px;
  }
}

.contextMenu {
  left: 3px;
}

.inputWidth {
  width: 271px;
}

.listTheme {
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.showAll {
  position: absolute;
  min-width: 318px;
  background-color: #ffffff;
  z-index: 9;
  border-radius: 0 0 10px 10px;
  box-shadow: 0px 0px 10px 2px rgba(128, 125, 174, 0.1);
}

.showListAll {
  max-height: fit-content;
}

.listTag {
  display: flex;
}

.listTagMore {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.listTagMore > li::before,
.listTagMore > li::after {
  display: block;
  position: absolute;
  top: 72px;
  margin: 225px 0 0 -21px;
  content: '';
  width: 1px;
  height: 38%;
  background-color: var(--separator_second);
}

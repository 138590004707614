.select {
  &__control {
    width: 412px !important;
    padding: 10px;

    background-color: #ffffff !important;
    box-shadow: 0 0 20px 2px rgba(128, 125, 174, 0.1) !important;
    border-radius: 4px !important;
    border: none !important;
    z-index: 10;
  }

  &__value-container {
    background: #f4f5f8;
    border-radius: 4px !important;
    padding: 8px 10px !important;
    font: var(--font-400-14-18-R) !important;
  }

  &__indicators {
    cursor: pointer !important;
    position: absolute;
    top: 19px;
    right: 24px;
  }

  &__menu {
    width: 412px !important;
    padding: 2px 10px 10px;
    margin: 0 !important;
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    background-color: #ffffff !important;
    box-shadow: 0 20px 20px 02px rgba(120, 120, 120, 0.08) !important;
    z-index: 20 !important;
    margin-block: 20px;
  }

  &__option {
    border-bottom: 1px solid #e8e8e8;
    padding-block: 10px !important;
    padding-inline: 0 10px !important;
    color: var(--main_black) !important;
    font: var(--font-400-14-18-R) !important;

    &:last-child {
      border: none;
    }
  }

  &__input {
    font: var(--font-400-14-18-R) !important;
  }

  &__input-container {
    margin: 0 !important;
    padding: 0 !important;
    font: var(--font-400-14-18-R) !important;
  }

  &__placeholder {
    margin: 0 !important;
  }
}

.select__control--menu-is-open {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

/* Scroll Bar */
.select__menu-list {
  padding: 0 !important;
  padding-right: 10px !important;
}

.select__menu-list::-webkit-scrollbar {
  width: 6px;
  height: 0;
  border-radius: 4px;
}

.select__menu-list::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.select__menu-list::-webkit-scrollbar-thumb {
  background: #b5b5b5;
  border-radius: 4px;
}

.select__menu-list::-webkit-scrollbar-thumb:hover {
  background: #b5b5b5;
  border-radius: 4px;
}

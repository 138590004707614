.feedListContainer {
  width: 1340px;
  margin: 0 auto;
}

.feedList {
  display: flex;
  gap: 100px;
}

.relationFeed {
  padding-bottom: 10px;
}

.active {
  margin-right: 10px;
  min-width: 9px;
  height: 9px;
  background-color: var(--valid);
  border-radius: 50%;
}

.inactive {
  margin-right: 10px;
  min-width: 9px;
  height: 9px;
  background-color: var(--normal);
  border-radius: 50%;
}

.inputWrapper {
  position: relative;
  width: 32px;
}

.inputWrapperClose {
  width: 32px;
}

.button {
  display: flex;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: #f4f5f8;
  border-radius: 30px;
  padding-left: 1px;
}

.buttonTransparent {
  display: flex;
  background: transparent;
  margin-top: 4px;
}

.option_button {
  background-color: transparent;
  text-align: left;
  font: var(--font-400-15-18-R);
}

.select {
  position: absolute;
  top: 32px;
  right: 0;
  z-index: 100;
}

.left {
  left: 0;
  z-index: 100;
  min-width: 130px;
}

.frames {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px 14px;
  background-color: var(--white);
  border-radius: 4px;
  box-shadow: 0 0 20px rgba(120, 120, 120, 0.08);
  position: relative;

  &:first-child:after {
    position: absolute;
    content: '';
    height: 2px;
    background-color: var(--input_border);
    width: calc(100% - 28px);
    top: 50%;
  }
  .option_button:last-child {
    color: #f23333;
  }
}

.container {
  position: relative;
  display: flex;
  align-items: center;
  padding: 13px 16px;
  width: 158px;
  height: 38px;
  background: var(--white);
  box-shadow: 0 0 20px 2px rgba(120, 120, 120, 0.08);
  border-radius: 12px;
  cursor: pointer;
  z-index: 2;

  &:active {
    outline: 1px solid var(--purple_active);
  }
}

.active {
  outline: 1px solid var(--purple_active);
}

.over {
  .placeholder {
    color: var(--link_hover);
  }
}

.placeholder {
  font: var(--font-400-15-18-R);
  color: var(--main_black);
  margin-right: 6.5px;
}

.list {
  position: absolute;
  width: 86px;
  height: 238px;
  display: flex;
  flex-direction: column;
  background: var(--white);
  box-shadow: 0 0 18px 2px rgba(128, 125, 174, 0.19);
  border-radius: 16px;
}

.down {
  top: 45px;
  right: 0;
}

.up {
  bottom: 44px;
  left: 73px;
}

.hide {
  display: none;
}

.item {
  font: var(--font-400-15-18-R);
  color: var(--main_black);
  cursor: pointer;

  &:hover {
    color: var(--link_hover);
  }

  &:nth-child(1) {
    padding: 15px 35px 9px 35px;
    border-bottom: 1px solid var(--main_background);
  }

  &:nth-child(2) {
    padding: 10px 35px 9px 35px;
    border-bottom: 1px solid var(--main_background);
  }

  &:nth-child(3) {
    padding: 10px 35px 9px 35px;
    border-bottom: 1px solid var(--main_background);
  }

  &:nth-child(4) {
    padding: 10px 30px 9px 30px;
    border-bottom: 1px solid var(--main_background);
  }

  &:nth-child(5) {
    padding: 10px 30px 9px 30px;
    border-bottom: 1px solid var(--main_background);
  }

  &:last-child {
    padding: 10px 30px 15px 30px;
  }
}

@import './colors.scss';
@import './fonts.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: var(--link_primary);

  &:hover {
    color: var(--link_primary-hover);
  }

  &:active {
    color: var(--link_primary-active);
  }
}

a,
input,
button,
textarea,
select {
  margin: 0;
  outline: none;
  border: none;
  font: inherit;
}

li {
  list-style-type: none;
}

ul {
  margin-left: 0;
  padding-left: 0;
}

h1 {
  margin: 0;
}
input[type='checkbox'] {
  -moz-appearance: initial; // Hack for Firefox Browsers
}

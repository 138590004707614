.container {
  width: 1340px;
  margin: 0 auto 43px;
}

.headerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 17px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.lastBlock {
  display: flex;
  flex-wrap: nowrap;
  gap: 18px;
}

.characteriscticsBlock {
  width: 661px;
}

.playlistCounts {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 42px;
}

.btnSetPlaylistOrder {
  background: transparent;
  display: flex;
  color: var(--link_hover);
  text-decoration: underline;
  text-decoration-skip-ink: none;
  font: var(--font-500-14-18-M);
}

.tags {
  position: relative;
  margin-bottom: 20px;
  display: flex;
  font: var(--font-500-14-18-M);

  h3 {
    margin-right: 5px;
    font: var(--font-500-14-18-M);
  }
}

.separator {
  margin-top: 24px;
}

.taxContainer {
  display: grid;
  grid-template-columns: 661px 661px;
  min-height: 172px;
  gap: 18px;
  margin: 0 auto;
}

.paramsWrapper {
  display: grid;
  gap: 18px;
}

.headerMargin {
  margin-bottom: 18px;
}

.flexDirection {
  flex-direction: column;
}

.tagsCompilations {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
}

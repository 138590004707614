.container {
  display: flex;
  flex-direction: column;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;

  div:nth-child(10) {
    margin-right: 0;
  }
}

.label {
  margin-bottom: 10px;
  font: var(--font-500-15-18-M);
}

.errorLabel {
  color: var(--error);
}

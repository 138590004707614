.container {
  span {
    margin: 0 10px 0 0 !important;

    &:last-child {
      margin: 0;
    }
  }
}

.heading {
  font: var(--font-700-18-24-Ral-B);
  color: var(--main_black);
}

.contentContainer {
  width: 1340px;
  margin: 18px auto 0 auto;
  position: relative;
}

.buttonsColumn {
  width: 30px;
  text-align: right;
}

.titleWrapper {
  margin-bottom: 14px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttons {
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 18px;
}

.titleColumnList {
  width: 250px;
}

.titleColumn {
  width: 240px;
}

.themeColumn {
  width: 72px;
  padding-left: 160px;
}

.compilationCountColumn {
  width: 360px;
  padding-left: 235px;
  span {
    color: var(--link_hover);
  }
}

.dataCreatedColumn {
  width: 133px;
  span {
    color: var(--link_hover);
  }
}

.contentItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1340px;
  height: 69px;
  margin-bottom: 10px !important;
  background: var(--white);
  box-shadow: 0 0 20px 2px rgba(120, 120, 120, 0.08);
  border-radius: 12px;

  &:last-child {
    margin: 0;
  }
}

.titleDesc {
  width: 363px;
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.themeDesc {
  width: 220px;
  padding-left: 20px;
}

.compilationCountDesc {
  width: 129px;
  padding-left: 20px;
}

.dataCreatedDesc {
  width: 97px;
}

.footer {
  margin-top: 24px;
  position: absolute;
  bottom: 25px;
  right: 40%;
  display: flex;
  justify-content: center;
}

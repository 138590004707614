.text {
  position: relative;
  font: var(--font-400-14-18-R);
  color: var(--main_black);

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: normal;
  white-space: pre-wrap;
  hyphens: auto;
  overflow-wrap: anywhere;

  &:hover .hint {
    visibility: visible;
    opacity: 1;
  }
}

.checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.checkbox + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}

.checkbox + label::before {
  content: '';
  display: inline-block;
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #b5b5b5;
  border-radius: 0.25em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
  box-sizing: border-box;
}

.checkbox:checked + label::before {
  border-color: #8179fe;
  background-color: #8179fe;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 9'%3e%3cpath fill='white' d='M9.79076 0.244113C10.0697 0.569598 10.0697 1.09818 9.79076 1.42367L4.07711 8.08959C3.79813 8.41508 3.34505 8.41508 3.06606 8.08959L0.20924 4.75663C-0.0697467 4.43115 -0.0697467 3.90256 0.20924 3.57707C0.488227 3.25159 0.941301 3.25159 1.22029 3.57707L3.5727 6.31896L8.78194 0.244113C9.06093 -0.0813711 9.51401 -0.0813711 9.79299 0.244113H9.79076Z'/%3e%3c/svg%3e");
}

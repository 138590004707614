.container {
  margin: 0 auto;
  width: 1340px;
  padding-bottom: 50px;
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headingWrapper {
  display: flex;
  align-items: center;
  gap: 6px;
}

.controls {
  display: flex;
  align-items: center;
  gap: 10px;
}

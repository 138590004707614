.input {
  font: var(--font-400-14-18-R);
  color: var(--main_black);
  position: relative;

  &::placeholder {
    font: var(--font-400-14-18-R);
    color: var(--text);
  }

  :global {
    .react-datepicker__day-names {
      display: flex;
    }

    .react-datepicker-popper[data-placement^='bottom'],
    .react-datepicker-popper[data-placement^='top'] {
      z-index: 11 !important;
    }
  }
}

.dateBlock {
  display: flex;
  flex-direction: column;
}

.dateRange {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  color: var(--placeholder_color);
  input {
    width: 72px;
  }
}

.headerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
}

.gridItem2 {
  width: 661px;
  height: 247px;
}

.active {
  display: flex;
  align-items: center;
  margin-right: 470px;
  margin-bottom: 20px;
}

.title1 {
  display: flex;
  word-spacing: normal;
  gap: 81px;
}

.title2 {
  display: flex;
  gap: 53px;
  margin: 16px 0 60px 0;
}

.container {
  display: flex;
  align-items: center;
  position: relative;
  height: 31px;
}

.label {
  margin-top: 4px;
  margin-right: 10px;
  height: 20px;
  font: var(--font-500-15-18-M);
  display: flex;
  gap: 3px;
}

.errorLabel {
  color: var(--error);
}

.add {
  width: 31px;
  height: 31px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2f5ff;
  border-radius: 30px;
}

.editButton {
  margin-left: 7px;
  width: 14px;
  height: 14px;
  background: var(--white);
  cursor: pointer;
}

.select {
  position: absolute;
  top: 43px;
}

.optionDescription {
  font: var(--font-400-15-18-R);
}

.editWrapper {
  display: flex;
  align-items: flex-end;
  height: 20px;
}

.ellipsis {
  position: relative;
  top: -4px;
}

.container {
  position: relative;
}

.block {
  width: 90px;
  height: 90px;
  margin: 0 10px 6px 0;
  background: #f4f5f8;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.select {
  position: absolute;
  bottom: 50px;
}

.image {
  width: 90px;
  height: 90px;
  border-radius: 4px;
}

.label {
  width: 90px;
  font: var(--font-400-14-18-R);
  word-wrap: break-word;
}

.hasName {
  color: #434343;
}

.noName {
  color: #7d7d7d;
}

.titleDescription {
  width: 100px;
  margin-bottom: 16px;
}

.editHover {
  position: absolute;
  top: 58px;
  left: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: #fff;
}

.plusButton {
  font-size: 0;
  line-height: 0;
  padding: 8px;
  background-color: #f4f5f8;
  border-radius: 50%;
  box-shadow: 0 0 10px 3px rgba(171, 171, 171, 0.15);
  svg path {
    fill: #434343;
  }
}

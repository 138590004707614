.container {
  display: flex;
  gap: 48px;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
}

.label {
  margin-bottom: 10px;
  font: var(--font-500-15-18-M);
}

.licenseDateInput {
  width: 150px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.labelDate {
  height: 46px;
  display: flex;
  align-items: center;
}

.dates {
  .label {
    margin-bottom: 17px;
  }

  .wrapper {
    gap: 36px;
  }
}

.inputDate {
  display: flex;
  //   align-items: center;
  gap: 10px;

  h3 {
    font: var(--font-500-15-18-M);
  }

  input {
    max-width: 150px;
  }
}

.disabledLabel {
  color: var(--disabled);
}

.activeLabel {
  color: var(--link_hover);
}

.activeDate {
  input {
    border: 1px solid var(--link_hover);
  }
}

.activePlaceholder {
  font: var(--font-400-13-17-R);
}

.errorLabel {
  color: var(--error);
}

.errorDate {
  input {
    border: 1px solid var(--error);
  }
}

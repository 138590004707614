.inputs {
  display: flex;
}

.inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.button {
  background: #8179fe;
  padding: 9px 16px;
  border-radius: 30px;
  color: white;
  margin-top: 14px;
  width: 140px;
  margin: 0 auto;
}

.select {
  min-width: 269px;
}

.container {
  display: flex;
  align-items: center;
  position: relative;
  height: 24px;
}

.label {
  color: var(--link_hover, #6860f1);
  font: var(--font-500-14-18-M);
  cursor: pointer;
}

.select {
  position: absolute;
  top: 25px;
  right: 0;
}

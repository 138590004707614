.container {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--button_primary);
  border-radius: 30px;
  width: 335px;
  height: 50px;
  background-color: #fff;
  cursor: pointer;

  &:disabled {
    border: 1px solid var(--button_primary-disabled);
  }
}

.desc {
  margin-left: 6px;
  font: var(--font-500-16-19-R);
  color: #7d7d7d;
}

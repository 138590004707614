@import './styles/reset';
@import './styles/colors';
@import './styles/fonts';
@import './styles/fonts/font.scss';
@import './styles/mixins';

// @import 'https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700&family=Roboto:wght@400;500;600;700&display=swap';

body {
  font-family: 'Roboto-Regular', sans-serif;
  font-style: normal;
  background-color: var(--main_background);
  color: var(--main_black);
}

button {
  cursor: pointer;
}

body.noscroll {
  height: 100%;
  overflow-y: hidden;
}

::-webkit-scrollbar {
  width: 6px;
  margin-right: 20px;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: #b5b5b5;
}

::-webkit-scrollbar-track {
  border-radius: 6px;
  background: #e8e8e8;
}

body .react-tooltip {
  max-width: 400px;
  white-space: pre-wrap;
  background: #e8e8e8;
  border: 1px solid #b5b5b5;
  border-radius: 4px;
  font: var(--font-400-13-17-R);
  color: #434343;
  z-index: 12;
  word-break: break-all;
  opacity: 1;
  padding-left: 10px;
  padding-right: 10px;
}

.react-tooltip-arrow {
  display: none;
}

.container {
  margin-right: 6px;
  text-align: center;

  &:last-child {
    margin-right: 0;
  }
}

.button {
  position: relative;
  background-color: var(--main_purple);
  color: var(--white);
  font: var(--font-500-16-18-Ral-SemiB);
  border-radius: 36px;
  cursor: pointer;

  &:hover {
    background-color: var(--purple_hover);
  }

  &:active {
    background-color: var(--purple_active);
  }
}

.active {
  background-color: var(--purple_active);
}

.entity {
  width: 130px;
  padding: 24px 0 26px 0;
}

.system {
  width: 166px;
  padding: 16px 25px;
  text-align: start;
}

.users {
  width: 161px;
  padding: 24px 0 26px 0;
}

.busines {
  width: 107px;
  padding: 24px 0 26px 0;
}

.dashboards {
  width: 136px;
  padding: 24px 0 26px 0;
}

.support {
  width: 142px;
  padding: 24px 0 26px 0;
}

.show {
  position: absolute;
  top: 100px;
  display: flex;
  flex-direction: column;
  padding: 24px 30px;
  background: var(--white);
  box-shadow: 0 0 20px 2px rgba(120, 120, 120, 0.08);
  border-radius: 20px;
  z-index: 11;
  min-width: 213px;
}

.item {
  padding: 5px 0;
  display: block;
  color: var(--main_black);
  text-align: left;
  font: var(--font-500-14-18-Ral-SemiB);

  &:first-child {
    padding: 0 0 5px 0;
  }

  &:last-child {
    padding: 5px 0 0 0;
  }

  &:hover {
    color: var(--link_hover);
  }
}

.hide {
  display: none;
}

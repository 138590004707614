.modal {
  display: flex;
  margin-left: -332px;
  margin-right: 7px;
  margin-top: 70px;
  z-index: 10;
}

.modalContent {
  border-top: 5px solid #e33d27;
  width: 305px;
  height: 81px;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 0px 18px 2px rgba(129, 129, 129, 0.33);
}

.modalBody {
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
  padding: 18.5px;
}

.warningTitle {
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
  width: 283px;
  line-height: 18px;
  margin: -25px 0 0 13px;
}

.warningText {
  font-size: 14px;
  line-height: 18px;
  font-weight: 100;
  width: 270px;
  margin: 6px 0 0 13px;
}

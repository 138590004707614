.wrapper {
  max-width: 1340px;
  margin: auto;
}

.title {
  font: var(--font-700-18-24-Ral-B);
}

.navWrapper {
  display: flex;
  margin-top: 20px;
  gap: 20px;
}

.navItem {
  display: flex;
  padding: 12px 14px;
  border-radius: 6px;
  box-shadow: 0px 0px 18px 2px #807dae1f;
  background: #ffffff;
  color: #434343;
  font: var(--font-400-15-18-R);
}

.wrapper {
  max-width: 1340px;
  margin: auto;
  padding-bottom: 50px;
}

.content {
}

.header {
  margin-bottom: 20px;
  font: var(--font-700-18-24-Ral-B);
}

.datePicker {
  height: 44px;
  min-width: 160px;
  background: #f4f5f8;
  padding: 0 12px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  > div {
    display: flex;
    align-items: center;
    gap: 6px;
  }
  svg {
    position: static !important;
  }
}

.select {
  > button {
    min-width: 160px !important;
  }
}

.button {
  background: #8179fe;
  padding: 9px 16px;
  border-radius: 30px;
  color: white;
  margin-top: 14px;
  width: 100%;
}

.datePickerInner {
  :global(.react-datepicker__month-container) {
    width: 245px;
  }

  padding: 14px 10px 20px;

  input {
    background: transparent;
    padding: 0;
  }
  h3 {
    font: var(--font-500-13-17-M);
    padding-bottom: 8px;
  }
}

.text {
  font: var(--font-400-15-18-R);
  color: var(--main_black);

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: normal;
  white-space: pre-wrap;
  hyphens: auto;
  overflow-wrap: anywhere;
}

// .react-tooltip-arrow {
//   display: none;
// }

// .react-tooltip {
//   max-width: 400px;
//   white-space: pre-wrap;
//   background: #e8e8e8;
//   border: 1px solid #b5b5b5;
//   border-radius: 4px;
//   font: var(--font-400-13-17-R);
//   color: #434343;
//   z-index: 12;
//   word-break: break-all;
// }

.normal {
  color: #434343;
}

.link {
  color: var(--link_primary);
}

.normalAlt {
  color: #7d7d7d;
}

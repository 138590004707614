.wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.countElement {
  width: 52px;
  height: 46px;
  padding: 14px;
  background: #f4f5f8;
  border-radius: 4px;
  font: var(--font-400-14-18-R);
  text-align: center;
}

.placeholder {
  color: var(--text);
  &::placeholder {
    color: var(--text);
  }
}
.option_choose {
  color: var(--main_black);
}

.title {
  font: var(--font-500-15-18-M);
}

input[type='number'] {
  appearance: textfield;

  &:hover,
  &:focus {
    appearance: number-input;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.percentage {
  position: absolute;
  width: 11px;
  height: 18px;
  padding-top: 42px;
  padding-left: 79px;
}

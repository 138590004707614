.container {
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  font: var(--font-400-14-18-R);
  color: var(--main_black);
  > div > span {
    -webkit-line-clamp: 1;
    max-width: 225px;
  }
}

.superior {
  background: var(--tag_superior);
  border: 1px solid var(--tag_superior-border);
  .corner {
    position: absolute;
    top: 72%;
    right: -8px;
    transform: rotate(45deg);
    width: 15px;
    height: 15px;
    background-color: var(--tag_superior-border);
  }
}

.high {
  background: var(--tag_high);
  border: 1px solid var(--tag_high-border);
  .corner {
    position: absolute;
    top: 72%;
    right: -8px;
    transform: rotate(45deg);
    width: 15px;
    height: 15px;
    background-color: var(--tag_high-border);
  }
}

.medium {
  background: var(--tag_medium);
  border: 1px solid var(--tag_medium-border);
  .corner {
    position: absolute;
    top: 72%;
    right: -8px;
    transform: rotate(45deg);
    width: 15px;
    height: 15px;
    background-color: var(--tag_medium-border);
  }
}

.low {
  background: var(--tag_low);
  border: 1px solid var(--tag_low-border);
  .corner {
    position: absolute;
    top: 72%;
    right: -8px;
    transform: rotate(45deg);
    width: 15px;
    height: 15px;
    background-color: var(--tag_low-border);
  }
}

.tagGroup {
  background: #f4f5f8;
  border: 1px solid #f4f5f8;
  .corner {
    position: absolute;
    top: 72%;
    right: -8px;
    transform: rotate(45deg);
    width: 15px;
    height: 15px;
    background-color: #f4f5f8;
  }
}

.mini {
  padding: 2px 6px 3px 6px;
}

.normal {
  padding: 4px 8px 5px 8px;
}

.noborder {
  border: none;
  .corner {
    display: none;
  }
}

.container {
  width: 100%;
  position: relative;
}

.frames {
  position: absolute;
  top: 35px;
  left: 0;
  display: flex;
  flex-direction: column;
  padding: 10px 14px;
  background: #ffffff;
  box-shadow: 0 0 20px 2px rgba(120, 120, 120, 0.08);
  border-radius: 4px;
  z-index: 99;
}

.option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 14px 10px 0;
  border-bottom: 1px solid #e8e8e8;
  text-align: start;
  font: var(--font-400-14-18-R);
  color: #434343;
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }
}

.addButtonWrapper {
  display: flex;
  align-items: center;
  padding-block: 20px;
  background-color: var(--white);
}

.addButtonWrapperError {
  @extend .addButtonWrapper;
  color: var(--error) !important;
  span {
    color: var(--error) !important;
  }
}

.add {
  width: 31px;
  height: 31px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2f5ff;
  border-radius: 30px;
}

.buttonDescription {
  color: #6860f1;
}

.select {
  position: absolute;
  top: 35px;
  left: 0;
}

.container {
  height: calc(100vh - 302px);
  width: 1358px;
  overflow-y: auto;
  position: relative;
}

::-webkit-scrollbar {
  width: 6px;
  margin-right: 20px;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: #b5b5b5;
}

::-webkit-scrollbar-track {
  border-radius: 6px;
  background: #e8e8e8;
}

.scrollToTopButton {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  visibility: hidden;
  left: 50%;
  top: calc(100vh - 20%);
  margin-left: 625px;
  width: 45px;
  height: 45px;
  background: #8179fe;
  border-radius: 50%;
  cursor: pointer;
}

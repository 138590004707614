.success {
  svg {
    position: relative;
    top: 2px;
  }
}

.error {
  display: flex;
  align-items: center;
  gap: 4px;
}
.errorText {
  font: var(--font-400-14-18-R);
  color: var(--main_black);
}

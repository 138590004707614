.common {
  width: 16px;
  height: 16px;
  background: var(--white);
  cursor: pointer;
}

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #f2f5ff;
}

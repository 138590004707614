.wrapper {
  width: 661px;
}

.content {
  display: flex;
  align-items: baseline;
  gap: 30px;
  position: relative;
  top: -7px;
  h3 {
    display: flex;
  }
}

.item {
  width: calc((100% - 30px * 2) / 3);
}

.group {
  @extend .item;
  white-space: nowrap;
  > div > span {
    -webkit-line-clamp: 1;
  }
}

.theme {
  @extend .item;
  div {
    white-space: nowrap;
  }
  > div > span {
    -webkit-line-clamp: 1;
  }
}

.themesWrapper {
  @extend .item;
  > div > div > span {
    -webkit-line-clamp: 1;
  }
}

.themesWrapper div {
  right: 0;
}

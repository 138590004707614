.contentWrapper {
  margin-top: 18px;
}

.title {
  display: flex;
  gap: 54px;
  padding-block: 10px;
  border-bottom: 1px solid #e8e8e8;
  padding-left: 10px;
  span {
    font: var(--font-500-13-17-M);
  }

  &_entity {
    width: 147px;
  }
  &_name {
    width: 195px;
  }
  &_composition {
    width: 195px;
  }
  &_type {
    width: 150px;
  }
  &_kind {
    width: 175px;
  }
  &_uuid {
    width: 62px;
  }
}

.feedWrapper {
  width: 1270px;
  min-height: 31px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-block: 20px;
  border-bottom: 1px solid #e8e8e8;
}

.input {
  padding-block: 0;
  border-bottom: none;
}

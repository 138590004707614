.container {
  position: relative;
  width: 240px;
  height: 166px;
  background: #ffffff;
  box-shadow: 0 0 16px 2px rgba(176, 175, 186, 0.2);
  border-radius: 16px;
}

.list {
  padding-left: 20px;
  padding-top: 20px;
}

.listItem {
  padding-bottom: 12px;
  font: var(--font-400-14-16-R);
}

.desc {
  padding-left: 6px;
  color: #434343;
}

.invalid {
  color: var(--error);
}

.normal {
  color: var(--main_black);
}

.triangle {
  position: absolute;
  bottom: 20px;
  right: -30px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 16px 0 16px 46px;
  border-color: transparent transparent transparent #ffffff;
  border-radius: 1px;
  transform: rotate(30deg);
}

.headerFeed {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
}

.titleFeed {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  align-items: center;
}

.title {
  font: var(--font-500-15-18-M);
}

.linkToFeed {
  display: flex;
  align-items: center;
  gap: 9px;
}

.link {
  display: flex;
  align-items: center;
  gap: 9px;
  font: var(--font-400-15-18-R);
  color: var(--main_black);
  text-decoration: underline;
  text-decoration-skip-ink: none;
}

.button {
  font-size: 0;
  line-height: 0;
  background-color: transparent;
}

.uuidNumber {
  max-width: 52px;
  font: var(--font-400-15-18-R);
  span {
    display: block;
    width: 52px;
    color: var(--text);
    white-space: nowrap;
  }
}

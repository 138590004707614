.input {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;

  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  border: 1px solid #929292;
  cursor: pointer;

  &:checked {
    border: 1px solid #8179fe;
  }

  &::before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background: #8179fe;
    border-radius: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: none;
  }
  &:checked::before {
    display: block;
  }
}

.label {
  display: flex;
  gap: 8px;
  align-items: center;
}

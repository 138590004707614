.container {
  display: flex;
  align-items: center;
  position: relative;
}

.label {
  margin-right: 10px;
  font: var(--font-500-16-16-M);
}

.buttonsWrapper {
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.add {
  width: 31px;
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f5f8;
  border-radius: 30px;
  svg path {
    fill: #434343;
  }
}

.trash {
  width: 31px;
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  background-color: #f4f5f8;
  svg {
    path {
      fill: #434343;
    }
  }
}

.select {
  position: absolute;
  top: 39px;

  &__control {
    width: 412px;
    height: 54px;
    left: 0;
    top: 0;

    background: #ffffff;
    box-shadow: 0 0 10px 2px rgba(128, 125, 174, 0.1);
    border-radius: 6px;
  }

  &__indicators {
    cursor: pointer !important;
  }

  &__menu {
    width: 412px !important;
    padding: 10px;
  }

  &__option {
    border-bottom: 1px solid #e8e8e8;

    &:last-child {
      border: none;
    }
  }
}

.left {
  right: 0;
}

.selectedOptions {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.option {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 2px;
}

.image {
  width: 54px;
  height: 54px;
  margin-bottom: 6px;
  border-radius: 50%;
}

.name {
  font: var(--font-400-13-17-R);
}

.editButton {
  width: 32px;
  height: 32px;
  background: #f4f5f8;
  border-radius: 30px;
  cursor: pointer;
  svg path {
    fill: #434343;
  }
  svg {
    width: 17px;
    height: 16px;
  }
}

.editDesc {
  font: var(--font-400-16-20-R);
  margin-right: 7px;
}

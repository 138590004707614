.container {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
}

.wrapper {
  max-width: 320px;
  background-color: #262525;
  margin: 35px auto 50px;
  color: #eeeeee;
  min-height: 550px;
}

.header {
  border-bottom: 1px solid rgba(228, 228, 228, 0.3);
}

.headerTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 6px;
}

.headerTitle {
  display: flex;
  padding: 9px 16px;
}

.headerTitleText {
  margin-left: 90px;
  font: var(--font-600-17-19-PN);
}

.childrenWrapper {
  display: grid;
  grid-template-columns: repeat(2, 138px);
  grid-template-rows: repeat(auto-fit, 80px);
  gap: 11px;
  padding: 20px 16px 85px;
}

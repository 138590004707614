.containerAudio {
  display: flex;
  align-items: center;
  height: 34px;
}

.titleAudio {
  display: flex;
  gap: 3px;
  margin-right: 2px;
  font: var(--font-500-15-18-M);
  color: #434343;
}

.errorTitle {
  color: var(--error);
}

.hidden {
  opacity: 0;
  height: 0;
  width: 0;
  line-height: 0;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.wrapperAudio {
  display: flex;
  align-items: center;
}

.repeat {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-left: 10px;
  font: var(--font-400-14-18-R);
  color: var(--link_hover);
  background-color: var(--white);
}

.container {
  margin: 0 auto;
  width: 1340px;
  height: calc(100vh - 150px);
}

.innerContainer {
  position: relative;
  max-width: 1340px;
  width: 100%;
}

.heading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.headingText {
  max-width: 444px;
  font: var(--font-400-15-18-R);
}

.mobileWrapper {
  padding-top: 34px;
}

.cardsWrapper {
  display: flex;
  justify-content: center;
}

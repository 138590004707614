:root {
  //400
  --font-400-12-14-R: 400 12px/14px Roboto-Regular, sans-serif;
  --font-400-12-15-R: 400 12px/15px Roboto-Regular, sans-serif;

  --font-400-13-14-R: 400 13px/14px Roboto-Regular, sans-serif;
  --font-400-13-17-R: 400 13px/17px Roboto-Regular, sans-serif;

  --font-400-14-16-R: 400 14px/16px Roboto-Regular, sans-serif;
  --font-400-14-18-R: 400 14px/18px Roboto-Regular, sans-serif;

  --font-400-15-18-R: 400 15px/18px Roboto-Regular, sans-serif;
  --font-400-15-20-R: 400 15px/20px Roboto-Regular, sans-serif;

  --font-400-16-16-R: 400 16px/16px Roboto-Regular, sans-serif;
  --font-400-16-19-R: 400 16px/19px Roboto-Regular, sans-serif;
  --font-400-16-20-R: 400 16px/20px Roboto-Regular, sans-serif;
  --font-400-17-19-R: 400 17px/19px Roboto-Regular, sans-serif;
  --font-400-23-30-R: 400 23px/30px Roboto-Regular, sans-serif;

  //500
  --font-500-12-14-M: 500 12px/14px Roboto-Medium, sans-serif;
  --font-500-12-16-M: 500 12px/16px Roboto-Medium, sans-serif;
  --font-500-12-17-M: 500 13px/17px Roboto-Medium, sans-serif;
  --font-500-12-18-M: 500 12px/18px Roboto-Medium, sans-serif;
  --font-500-13-17-M: 500 13px/17px Roboto-Medium, sans-serif;
  --font-500-14-18-M: 500 14px/18px Roboto-Medium, sans-serif;

  --font-500-13-17-R: 500 13px/17px Roboto-Medium, sans-serif;
  --font-500-15-18-R: 500 15px/18px Roboto-Medium, sans-serif;
  --font-500-15-18-M: 500 15px/18px Roboto-Medium, sans-serif;
  --font-500-16-16-R: 500 16px/16px Roboto-Medium, sans-serif;
  --font-500-16-16-M: 500 16px/16px Roboto-Medium, sans-serif;
  --font-500-16-19-R: 500 16px/19px Roboto-Medium, sans-serif;
  --font-500-17-19-R: 500 17px/19px Roboto-Medium, sans-serif;
  --font-500-23-30-R: 500 23px/30px Roboto-Regular, sans-serif;
  --font-500-60-75-R: 500 60px/75px Roboto-Regular, sans-serif;

  --font-500-14-18-Ral-SemiB: 500 14px/18px Raleway-SemiBold, sans-serif;
  --font-500-16-18-Ral-SemiB: 500 16px/18px Raleway-SemiBold, sans-serif;

  //600
  --font-600-13-17: 600 13px/17px Roboto, sans-serif;
  --font-600-15-15-PN: 600 15px/15px Proxima Nova Semibold, sans-serif;
  --font-600-17-19-PN: 600 17px/19px Proxima Nova Semibold, sans-serif;

  --font-600-14-18-Ral-SemiB: 600 14px/18px Raleway-SemiBold, sans-serif;
  --font-600-16-18-Ral-SemiB: 600 16px/18px Raleway-SemiBold, sans-serif;
  --font-600-18-24-Ral-SemiB: 600 18px/24px Raleway-SemiBold, sans-serif;
  --font-600-18-27-Ral-SemiB: 600 18px/27px Raleway-SemiBold, sans-serif;

  //700
  --font-700-17-24-Ral-B: 700 17px/24px Raleway-Bold, sans-serif;
  --font-700-15-18-Ral: 700 15px/18px Raleway, sans-serif;
  --font-700-18-21-Ral: 700 18px/21px Raleway, sans-serif;
  --font-700-18-21-Ral-B: 700 18px/21px Raleway-Bold, sans-serif;
  --font-700-18-24-Ral-B: 700 18px/24px Raleway-Bold, sans-serif;

  --font-500-80-80-RC: 500 80px/80px 'Roboto-Condensed-Medium', sans-serif;
  --font-500-40-45-RC: 500 40px/45px 'Roboto-Condensed-Medium', sans-serif;
  --font-500-30-35-RC: 500 30px/35px 'Roboto-Condensed-Medium', sans-serif;

  --font-600-18-24-RF: 600 18px/24px 'Roboto Flex', sans-serif;
  --font-600-20-28-RF: 600 20px/28px 'Roboto Flex', sans-serif;
  --font-600-21-28-RF: 600 21px/28px 'Roboto Flex', sans-serif;

  --font-700-18-21-RF: 700 18px/21px 'Roboto Flex', sans-serif;
}

.container {
  display: flex;
  flex-direction: column;
  padding: 14px 30px 28px 30px;
  background-color: var(--white);
  box-shadow: 0 0 20px 2px rgba(120, 120, 120, 0.08);
  border-radius: 30px;
  position: relative;
  min-height: 194px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.headerAndPlus {
  display: flex;
  align-items: center;
  gap: 10px;
}

.add {
  width: 31px;
  height: 31px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2f5ff;
  border-radius: 30px;
}

.trash {
  width: 31px;
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  background-color: #f4f5f8;
  svg {
    path {
      fill: #434343;
    }
  }
}

.label {
  display: flex;
  gap: 3px;
  font: var(--font-700-17-24-Ral-B);
  color: var(--main_black);
}

.option {
  position: relative;
  display: flex;
  align-items: center;
  cursor: grab;
  width: 383px;
  margin-right: 32px;
  height: 20px;
}

.select {
  position: absolute;
  top: 55px;
  left: 130px;
}

.selectedOptions {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 14px;
}

.point {
  display: flex;
  align-items: center;
  width: 6px;
  height: 6px;
}

.name {
  padding-left: 6px;
  font: var(--font-400-15-18-R);
  text-decoration-line: underline;
  color: #434343;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.DnDIcon {
  position: absolute;
  top: 5px;
  right: -20px;
}
.startDrag {
  background: #ffffff;
  box-shadow: 0 0 4px rgba(101, 101, 101, 0.25);
  border-radius: 8px;
}

.deleteButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  display: none;
  margin-left: 8px;
  padding: 2px;
  > svg {
    width: 14px;
    height: 14px;
  }
}

.option:hover .deleteButton {
  display: flex;
}

.wrapper {
  width: 661px;
}

.title {
  display: flex;
  justify-content: space-between;
  width: 100%;

  span {
    display: flex;
    gap: 2px;
  }
}

.content {
  display: flex;
  flex-direction: column;

  .separator {
    width: 100%;
  }

  .showAll {
    color: var(--link_hover, #6860f1);
    font: var(--font-500-14-18-M);
    margin-left: auto;
    cursor: pointer;
  }
}

.block {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
  margin-bottom: 16px;

  .topic {
    display: flex;
    flex-direction: column;
    width: 170px;
    gap: 6px;

    h4 {
      color: var(--main_black, #434343);
      font: var(--font-500-15-18-M);
    }

    span {
      color: var(--main_black, #434343);
      font: var(--font-400-14-18-R);
    }
  }
}

.errorLabel {
  color: var(--error);
}

.star {
  font: var(--font-500-15-18-R);
}

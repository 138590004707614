.modal {
  position: absolute;
  margin: 10px 0 0 -250px;
  z-index: 9999;
}

.modalContent {
  border-top: 5px solid #e33d27;
  width: 416px;
  height: 249px;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 0px 18px 2px rgba(129, 129, 129, 0.33);
}

.modalHeader {
  width: 250px;
  height: 15px;
  font-size: 14px;
  font-weight: bold;
  margin: -25px 0 0 30px;
  padding: 5px;
}

.modalIcon {
  margin: 10px 0 9.5px 20px;
  padding: 7px 0 0 7.5px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: var(--button_delete-bg);
}

.modalBody {
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
  padding: 18.5px;
  border-top: 1px solid #eeeeee;
}

.modalFooter {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
}

.button1 {
  color: #6860f1;
  background: #f2f5ff;
  border-radius: 30px;
  padding: 9px 16px;
  margin-left: 20px;
}

.button2 {
  color: #000000;
  background: #f4f5f8;
  border-radius: 30px;
  padding: 9px 16px;
}

.wrapper {
  display: flex;
  gap: 42px;
}

.blockWrapper {
  display: flex;
  width: 662px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.input {
  width: 310px;
  margin-bottom: 24px;

  input {
    width: 310px;
  }
}

.name {
  font: var(--font-500-15-18-M);
  color: var(--main_black);
}

.entityName {
  font: var(--font-400-15-18-R);
  color: var(--text);
}

.inputTextArea {
  width: 662px;
  textarea {
    width: 662px;
  }
}

// .imageContainer {
//   display: flex;
//   align-items: flex-start;
//   gap: 44px;
//   width: 524px;
// }

.secondInput {
  width: 612px;
  input {
    width: 612px;
  }
  textarea {
    width: 612px;
  }
}

.btnSetPlaylistOrder {
  background: transparent;
  display: flex;
  color: var(--link_hover);
  text-decoration: underline;
  text-decoration-skip-ink: none;
  font: var(--font-500-14-18-M);
  cursor: pointer;
}

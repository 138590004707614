.topWrapper {
  display: flex;
  gap: 42px;
  margin-bottom: 24px;
}

.blockTitle {
  padding-top: 10px;
  font: var(--font-500-15-18-M);
}

.blockTitleError {
  @extend .blockTitle;
  color: var(--error);
}

.blockElement {
  .separator {
    margin-bottom: 0;
  }
}
.delete_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 100%;
  margin-top: 28px;
}

.blockRow {
  margin-bottom: 24px;
  margin-top: 14px;
  display: flex;
  gap: 42px;
}

.description {
  margin-top: 28px;
  max-width: 353px;
}

.button {
  display: flex;
  gap: 6px;
  align-items: center;
  width: fit-content;
  margin-top: 24px;
  background: transparent;
  color: #6860f1;
  font: var(--font-500-14-18-M);

  .plus {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    background: #f4f5f8;
    border-radius: 100%;
  }
}

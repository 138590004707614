.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 325px;
  min-height: 350px;
}

.content {
  display: flex;
  flex-wrap: nowrap;
  gap: 40px;
  position: relative;
}

.column {
  display: flex;
  flex-direction: column;
  width: 325px;
}

.title {
  font: var(--font-500-16-16-M);
  margin-bottom: 10px;
}

.filtersList {
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 30px;
}

.clearButton {
  font: var(--font-500-14-18-M);
  background-color: transparent;
  text-decoration: underline;
  text-decoration-skip-ink: none;
}

.img {
  margin-left: 10px;
  border-radius: 4px;
}

.audio {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f4f5f8;
  border-radius: 4px;
  width: 204px;
  height: 34px;
}

.progressBar {
  position: relative;
  width: 24px;
  height: 24px;
}

.close {
  position: absolute;
  top: 2px;
  left: 8px;
  svg {
    width: 8px;
    height: 8px;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 210px;
}

.title {
  margin-bottom: 10px;
  font: var(--font-500-15-18-M);
  display: flex;
  gap: 3px;
}

.errorTitle {
  color: var(--error);
}

.select {
  // TODO : переделать стили
  :global {
    .css-13cymwt-control,
    .css-t3ipsp-control {
      background-color: rgba(244, 245, 248, 1);
      border: none;
      font: var(--font-400-14-18-R);
      height: 46px;
      box-shadow: none;
    }

    .css-tj5bde-Svg {
      fill: #434343;
    }

    .css-1u9des2-indicatorSeparator {
      display: none;
    }
  }
}

.errorSelect {
  :global {
    .css-13cymwt-control,
    .css-t3ipsp-control {
      border: 1px solid var(--error);
    }
  }
}

.container {
  display: flex;
  align-items: center;
}

.paginationContainer {
  display: flex;
  list-style-type: none;

  height: 38px;
  background: #ffffff;
  box-shadow: 0 0 20px 2px rgba(120, 120, 120, 0.08);
  border-radius: 12px;

  .paginationItem {
    padding: 11px 14px 10px 15px;
    height: 38px;
    background: var(--white);
    border-radius: 12px;
    color: var(--main_black);
    cursor: pointer;
    user-select: none;

    &:hover {
      color: var(--link_hover);
    }

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }

    &.selected {
      background: #8179fe;
      border-radius: 12px;
      color: var(--white);

      &:hover {
        color: var(--white);
      }
    }

    &.disabled {
      pointer-events: none;
      color: #7d7d7d;

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}

.rightButton {
  margin-right: 16px;
}

.leftButton {
  margin-left: 16px;
}

.buttonContainer {
  background-color: var(--main_background);
  border-radius: 0;
  cursor: pointer;
}

.rightDesc {
  margin-right: 5.05px;
}

.leftDesc {
  margin-left: 5.05px;
}

.buttonWrapper {
  user-select: none;
  display: flex;
  align-items: center;
}

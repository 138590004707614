.inputContainer {
  position: relative;
  width: 335px;
  height: 58px;
  margin: 15px 0 32px 0;
}

.passwordShow {
  position: absolute;
  left: 310px;
  top: 32px;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.input {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: none;
  z-index: 1;
  border: 1px solid var(--input_border);
  border-radius: 8px;
  padding-left: 14px;

  &:focus,
  &:active,
  .input:not(:placeholder-shown) {
    ~ .tooltip {
      visibility: visible;
    }
  }
}

.invalid {
  border: 1px solid var(--error);

  &:hover,
  &:focus,
  &:active {
    border: 1px solid var(--error);
  }
}

.label {
  position: absolute;
  padding: 0 3px;
  z-index: 0;
  left: 14px;
  top: 19px;
  font: var(--font-400-16-19-R);
  color: #7d7d7d;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  cursor: text;
}

::placeholder {
  color: transparent;
}

.input:focus + .label {
  z-index: 10;
  top: -6px;
  left: 12px;
  font: var(--font-500-12-14-M);
  color: #7d7d7d;
  background-color: var(--white);
}

.input:not(:placeholder-shown) + .label {
  z-index: 10;
  top: -6px;
  left: 12px;
  font: var(--font-500-12-14-M);
  color: #7d7d7d;
  background-color: var(--white);
}

.input:focus {
  border: 1px solid var(--input_border-focus);
}

.errorText {
  position: absolute;
  top: 60px;
  left: 0;
  font: var(--font-400-12-14-R);
  color: var(--error);
}

.tooltip {
  position: absolute;
  right: 410px;
  top: -110px;
  visibility: hidden;
  z-index: 11;
}

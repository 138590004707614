.container {
  display: flex;
  align-items: center;
  position: relative;
  width: 400px;
}

.container_select {
  position: absolute;
}

.label {
  margin-top: 4px;
  margin-right: 10px;
  height: 20px;
  font: var(--font-500-15-18-M);
  display: flex;
  gap: 3px;
}

.errorLabel {
  color: var(--error);
}

.selectWrapper {
  position: relative;
}

.add {
  width: 31px;
  height: 31px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2f5ff;
  border-radius: 30px;
}

.select {
  position: absolute;
  top: 43px;
  border: none;
  &:focus,
  &:hover {
    border: none;
  }
}

.editWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: 239px;
}

.optionDescription {
  padding-top: 2px;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  div {
    left: 0 !important;
  }
  span {
    display: inline;
    font: var(--font-400-15-18-R) !important;
  }
}

:root {
  --main_background: #f2f5ff;
  --main-fields: #f4f5f8;

  --input_border: #e8e8e8;
  --input_border-focus: #8179fe;
  --checkbox-border: #b5b5b5;
  --input_background: #f4f5f8;
  --placeholder_color: #7d7d7d;

  --separator_main: #dfddff;
  --separator_second: #e8e8e8;

  --error: #e33d27;
  --disabled: #929292;

  --link_primary: #8179fe;
  --link_primary-hover: #938cff;
  --link_primary-active: #675feb;

  --link_hover: #6860f1;

  --button_primary: #8179fe;
  --button_primary-hover: #938cff;
  --button_primary-active: #675feb;
  --button_primary-disabled: #b5b5b5;
  --button_delete: #f4f5f8;
  --button_delete-border: #f23333;
  --button_delete-bg: #ffeded;
  --button_delete-text: #e33d27;

  --valid: #3bba49;
  --normal: #7d7d7d;

  --main_purple: #8179fe;
  --purple_hover: #938cff;
  --purple_active: #675feb;

  --white: #fff;

  --main_black: #434343;

  --text: #7d7d7d;

  --tag_superior: #dfeafe;
  --tag_superior-border: #5f95f9;
  --tag_high: #eee6fd;
  --tag_high-border: #8c5af0;
  --tag_medium: #f8d9fb;
  --tag_medium-border: #e77ff0;
  --tag_low: #fce4c9;
  --tag_low-border: #f3a54a;

  --statistics_title: #3e9fbe;
  --orange-three: #ed7b30;

  --light-violet: #e5e3ff;
}

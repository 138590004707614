.container {
  position: relative;
}

.reset {
  color: var(--purple_active);
  font: var(--font-500-12-17-M);
  cursor: pointer;
}

.resetModal {
  margin: 0;
  top: 27px;

  & > div {
    width: 240px;
    height: 125px;
  }
}

.resetBody {
  padding: 24px 38px 18px 38px;
}

.resetFooter {
  & > button:nth-child(1) {
    color: var(--main_black);
  }

  & > button:nth-child(2) {
    color: var(--link_hover);
  }
}

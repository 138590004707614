.count {
  font: var(--font-500-15-18-M);
}

.entity {
  font: var(--font-400-15-18-R);
  color: #7d7d7d;
}

.label {
  display: flex;
  gap: 3px;
}

.block {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.headerBlock {
  width: 74px;
  font: var(--font-500-15-18-M);
  flex-shrink: 0;
}

.blockList {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.blockListItem {
  width: 150px;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  margin-right: 14px;
}

.inputBlock {
  display: flex;
  flex-wrap: nowrap;
  position: relative;
}

.input {
  font: var(--font-400-14-18-R);
  color: var(--main_black);
  &::placeholder {
    color: var(--placeholder_color);
  }
  width: 100%;
  max-width: 249px;
}

.clearInput {
  background: transparent;
  position: absolute;
  right: 0;
}

.table {
  border: 1px solid #d7d7d7;
  width: 100%;
  border-spacing: 0;
  border-radius: 6px;
  background: white;

  th {
    background: #f4f5f8;
    border-right: 1px solid #d7d7d7;
    border-bottom: 1px solid #d7d7d7;

    padding: 8px 5px;
    text-align: center;
    font: var(--font-500-13-17-M);
  }

  td {
    border-bottom: 1px solid #d7d7d7;
    border-right: 1px solid #d7d7d7;
    padding: 8px 5px;
    text-align: center;
    height: 33px;
  }
}

.table > thead > tr > th:first-child {
  border-top-left-radius: 6px;
}

.table > thead > tr > th:last-child {
  border-top-right-radius: 6px;
  border-right: none;
}

.table > tbody > tr:last-child > td {
  border-bottom: none;
}

.table > tbody > tr > td:last-child {
  border-right: none;
}

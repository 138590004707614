.container {
  position: relative;
  padding-bottom: 20px;
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.labelAndButton {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.label {
  margin-right: 10px;
  font: var(--font-500-15-18-M);
}

.add {
  width: 31px;
  height: 31px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2f5ff;
  border-radius: 30px;
}

.trash {
  width: 31px;
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  background-color: #f4f5f8;
  svg {
    path {
      fill: #434343;
    }
  }
}

.select {
  position: absolute;
  top: 45px;
}

.selectedOptions {
  display: flex;
  align-items: flex-start;
  margin-top: 6px;
}

.option {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-right: 16px;
  width: 80px;
}

.image {
  width: 54px;
  height: 54px;
  margin-bottom: 6px;
  border-radius: 50%;
}

.name {
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    display: -webkit-box;
    font: var(--font-400-13-17-R);
    -webkit-line-clamp: 2; // max nb lines to show
    -webkit-box-orient: vertical;
    width: 80px;
    word-wrap: break-word;
  }
}

.selected {
  padding: 2px;
  outline: 3px solid var(--link_primary);
}

.deleting {
  background-color: #ffeded;
  svg {
    path {
      fill: #f23333;
    }
  }
}

.wrapper {
  display: block;
  position: relative;
  height: 80px;
  width: 138px;
  padding: 12px;
  box-shadow: 0px 0px 5px rgba(171, 171, 171, 0.25);
  border-radius: 12px;
  background-color: #eadfff;
  overflow: hidden;
  text-overflow: ellipsis;

  &:nth-child(5n - 4) {
    background-color: #eadfff;
  }
  &:nth-child(5n - 3) {
    background-color: #ffeeb3;
  }
  &:nth-child(5n - 2) {
    background-color: #ffd78f;
  }
  &:nth-child(5n - 1) {
    background-color: #dbeeff;
  }
  &:nth-child(5n - 5) {
    background-color: #e1fed4;
  }
}

.title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow-y: clip;
  font: var(--font-600-15-15-PN);
  color: #222222;
  word-break: keep-all;
  &:first-letter {
    text-transform: uppercase;
  }
}

.titleLong {
  @extend .title;
  word-break: break-all;
}

.img {
  position: absolute;
  bottom: 0;
  right: 8px;
  width: 80px;
  height: 40px;
}

.wrapper {
  width: 1340px;
  margin: auto;
  padding-bottom: 50px;
}

.header {
  display: flex;
  justify-content: space-between;
}

.title {
  max-width: 313px;
  font: var(--font-700-18-24-Ral-B);
  flex-shrink: 0;
}

.controls {
  display: flex;
  justify-content: space-between;
  width: 100%;
  > div {
    display: flex;
    gap: 10px;
  }
}

.left {
  display: flex;
  align-items: center;
  gap: 25px;
  width: 100%;
}

.right {
  display: flex;
  gap: 20px;
}

.date {
  color: #3e9fbe;
  font: var(--font-600-13-17);
  font-style: italic;
}

.dateTitle {
  font: var(--font-500-12-16-M);
}

.buttons {
  display: flex;
  gap: 8px;
}

.tables {
  margin-top: 22px;
  display: flex;
  flex-direction: column;
  gap: 28px;
}

.topWrapper {
  display: flex;
  gap: 28px;
}

.tableTitle {
  font: var(--font-500-15-18-M);
  margin-bottom: 16px;
}

.container {
  display: flex;
  align-items: center;
  gap: 6px;
}
.checkbox {
  position: relative;
  width: 16px;
  height: 16px;
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    content: '';
    border: 1px solid var(--checkbox-border);
    border-radius: 2px;
    background-color: var(--white);
  }
}
.label {
  font: var(--font-400-16-16-R);
}
.selected {
  &::after {
    position: absolute;
    border: none;
    background: var(--main_purple) url('../assets/icon_checkbox.svg');
  }
}

.separator {
  border-top: 1px solid var(--separator_main);
  width: calc(100% + 19px);
}
.grey {
  border-top: 1px solid var(--separator_second);
}

.margin {
  margin-bottom: 20px;
}

.modal {
  height: 100vh;
  width: 100vw;
  background-color: rgba(38, 38, 38, 0.44);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
  z-index: 3;
}

.active {
  opacity: 1;
  pointer-events: all;
}

.modalContent {
  display: flex;
  flex-direction: column;
  position: relative;
  width: auto;
  padding: 16px 30px;
  padding-bottom: 28px;
  border-radius: 12px;
  background: white;
}

.closeButton {
  position: absolute;
  right: 26px;
  top: 26px;
  background: var(--white);
  cursor: pointer;
  z-index: 10;
  font-size: 0;
  line-height: 0;
  width: 11px;
  height: 11px;
  svg {
    width: 11px;
    height: 11px;
  }
}

.header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.title {
  font: var(--font-700-17-24-Ral-B);
  margin-bottom: 14px;
}

.separator {
  width: 100%;
}

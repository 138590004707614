.bank {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 18px;
  padding-top: 18px;
  border-bottom: 1px solid #e8e8e8;
}

.bankRight {
  display: flex;
  align-items: center;
  gap: 42px;
}

.bankSelects {
  display: flex;
  align-items: center;
  gap: 14px;

  > div {
    width: 230px;
    min-width: 230px;
  }
}

.bankTitle {
  display: flex;
  align-items: center;
  gap: 8px;
  font: var(--font-400-16-16-R);
}

.bankButtons {
  display: flex;
  gap: 8px;
}

.wrapper {
  max-width: 1340px;
  margin: auto;
  padding-bottom: 50px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font: var(--font-700-18-24-Ral-B);
}

.left,
.right {
  display: flex;
  align-items: center;
  gap: 20px;
}

.table {
  margin-top: 24px;
}

.ownersSelect {
  width: 230px;
}

.selects {
  display: flex;
  gap: 10px;
}

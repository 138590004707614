.topWrapper {
  display: flex;
  gap: 42px;
  margin-bottom: 24px;

  & > div:nth-child(1) {
    width: 231px;
  }

  & > div:nth-child(2) {
    width: 204px;
  }

  & > div:nth-child(3) {
    width: 226px;
  }

  & > div:nth-child(4) {
    width: 285px;
  }
}

.ownersSelect {
  min-width: 160px;
}

.button {
  background: #8179fe;
  padding: 0 16px;
  border-radius: 30px;
  color: white;
  width: 100%;
  margin-left: 10px;
}

.wrapper {
  width: 1340px;
  margin: auto;
  padding-bottom: 50px;
}

.header {
  display: flex;
  justify-content: space-between;
}

.left {
  display: flex;
  align-items: center;
  gap: 25px;
  width: 100%;
}

.title {
  max-width: 313px;
  font: var(--font-700-18-24-Ral-B);
  flex-shrink: 0;
  margin-right: 150px;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  > div {
    display: flex;
    gap: 10px;
  }
}

.tables {
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 40px;
}

.table {
  width: 100%;
  margin-top: 26px;
}

.tableTitle {
  font: var(--font-500-15-18-M);
  margin-bottom: 18px;
}

.filter {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px 12px;
  background-color: var(--white);
  font: var(--font-500-14-18-M);
  color: var(--main_black);
  border-radius: 6px;
  border: 1px solid var(--input_border);
}

.buttonClear {
  background: transparent;
  font-size: 0;
  line-height: 0;
}

.buttonOpen {
  background: transparent;
}

.icon {
  width: 16px;
  height: 10px;
  position: relative;
  top: -1px;
}

.filterSearch {
  border: 1px solid var(--main_black);
}

.text {
  cursor: pointer;
}

.buttonEdit {
  width: 26px;
  height: 26px;
  background: #f2f5ff;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -4px 0;
}

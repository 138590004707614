.container {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 10px 30px 30px 30px;
  background-color: var(--white);
  box-shadow: 0 0 20px 2px rgba(120, 120, 120, 0.08);
  border-radius: 30px;
}

.header {
  font: var(--font-700-17-24-Ral-B);
  padding: 4px 0 0;
}

.containerHeader {
  display: flex;
  margin-bottom: 14px;
}

.titleHeader {
  display: flex;
  gap: 140px;
  margin-bottom: 14px;
}

.label {
  margin-right: 10px;
  font: var(--font-500-16-16-M);
}

.buttonsWrapper {
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.add {
  width: 31px;
  height: 31px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--main_background);
  border-radius: 30px;
}

.trash {
  width: 31px;
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--input_border);
  border-radius: 30px;
}

.select {
  position: absolute;
  top: 39px;

  &__control {
    width: 412px;
    height: 54px;
    left: 0;
    top: 0;

    background: #ffffff;
    box-shadow: 0 0 10px 2px rgba(128, 125, 174, 0.1);
    border-radius: 6px;
  }

  &__indicators {
    cursor: pointer !important;
  }

  &__menu {
    width: 412px !important;
    padding: 10px;
  }

  &__option {
    border-bottom: 1px solid var(--input_border);

    &:last-child {
      border: none;
    }
  }
}

.tariffList {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.tariffLine {
  display: flex;
  font: var(--font-400-16-16-R);
  color: var(--text);
}

.tariffName {
  width: 182px;
  text-decoration: underline;
  color: var(--main_black);
}

.tariffDiscount {
  width: 190px;
}

.tariffCommission {
  width: 278px;
}

.tariffVat {
  width: 30px;
}

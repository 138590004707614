.container {
  position: relative;
  z-index: 100;
}

.select {
  position: absolute;
  top: 0;
  right: 0;
}

.left {
  left: 0;
}

.frames {
  min-width: 160px;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  padding: 10px 14px;
  background: #ffffff;
  box-shadow: 0 0 20px 2px rgba(120, 120, 120, 0.08);
  border-radius: 4px;
}

.option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 14px 10px 0;
  border-bottom: 1px solid #e8e8e8;
  text-align: start;
  font: var(--font-400-14-18-R);
  color: #434343;
  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }
}

.add {
  width: 31px;
  height: 31px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2f5ff;
  border-radius: 30px;
}

.buttonDescription {
  color: #6860f1;
}

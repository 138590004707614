.headingWrapper {
  width: 1340px;
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
  margin-inline: auto;
  z-index: 10;
}

.createGroupTheme {
  height: 36px;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: var(--main_purple);
  border-radius: 30px;
}

.desc {
  padding-left: 5px;
  font: var(--font-400-15-18-R);
  color: var(--white);
}

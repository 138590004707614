.input {
  padding-block: 0;
  border-bottom: none;
}

.inputWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.imageTitle {
  font: var(--font-500-16-16-M);
  color: var(--main_black);
}

.imageContainer {
  display: flex;
  align-items: flex-start;
  margin-top: 14px;
}

.imagePadding {
  margin-right: 42px;
}

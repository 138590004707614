.listItemTheme {
  position: relative;
  cursor: pointer;
  max-height: 27px;
  align-self: flex-start;
}

.listItemThemeName {
  color: var(--main_black);
  font: var(--font-400-14-18-R);
}

.menuTheme {
  top: 0;
  left: calc(100% + 10px);
}

.inputWidth {
  width: 271px;
  top: 20px;
}

.contextMenu {
  margin-left: -14px;
}

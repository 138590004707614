.container {
  display: flex;
  gap: 6px;
}

.title {
  font: var(--font-500-15-18-M);
}

.linkText {
  color: var(--main_black);
  text-decoration: underline;
  text-decoration-skip-ink: none;
  span {
    font: var(--font-400-15-18-R);
  }
}

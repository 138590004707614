.container {
  width: 1200px;
  display: flex;
  align-items: center;
}

.title {
  width: 132px;
  font: var(--font-500-15-18-M);
  color: var(--main_black);
  margin-right: 73px;
}

.errorTitle {
  color: var(--error);
}

.text {
  font: var(--font-400-14-18-R);
  color: var(--text);

  &:last-child {
    margin-bottom: 0;
  }
}

.overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  color: var(--main_black);
}

.name {
  width: 195px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font: var(--font-400-14-18-R);
  text-decoration: underline;
  text-decoration-skip-ink: none;
  margin-right: 54px;
  color: var(--main_black);
}

.composition {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 195px;
  font: var(--font-400-14-18-R);
  text-decoration: underline;
  text-decoration-skip-ink: none;
  margin-right: 54px;
  color: var(--main_black);
}

.typeElement {
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  font: var(--font-400-14-18-R);
  color: var(--text);
  margin-right: 54px;
}

.feedType {
  width: 175px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  font: var(--font-400-14-18-R);
  color: var(--text);
  margin-right: 54px;
}

.feedGroupId {
  font: var(--font-400-14-18-R);
  color: var(--text);
  width: 62px;
}

.tooltip span {
  color: var(--text);
}

.circle {
  padding-top: 2px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: var(--button_delete-bg);
  cursor: pointer;
}

.flat {
  margin-right: 8px;
  background: var(--button_delete-bg);
  border-radius: 30px;
  padding: 8px 16px;
  color: var(--button_delete-text);
  border: 1px solid var(--button_delete-border);
}
.grey {
  background-color: #f4f5f8;
  svg {
    path {
      fill: #434343;
    }
  }
}

.text {
  font-size: 14px;
  line-height: 18px;
  left: 4.81%;
  right: 4.81%;
  font-weight: 100;
  margin-top: 8px;
}

.modalCircle {
  padding-top: 290px;
  left: 1160px;
}

.modalFlat {
  margin-top: 12px;
}

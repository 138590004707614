.wrapper {
  display: flex;
  flex-direction: column;
  width: 265px;
}

.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px;
  height: 46px;
  background: var(--white);
  background: #f4f5f8;
  border-radius: 4px;
  cursor: pointer;
  &:active {
    outline: 1px solid var(--purple_active);
  }
}

.errorContainer {
  border: 1px solid var(--error);
}

.errorContainer .disabled {
  &:active {
    outline: none;
  }
}

.active {
  outline: 1px solid var(--purple_active);
  svg {
    transition-duration: 0.3s;
    transform: rotate(180deg);
  }
  div {
    svg {
      transform: rotate(0deg);
    }
  }
}

.over {
  .placeholder {
    color: var(--link_hover);
  }
}

.title {
  margin-bottom: 10px;
  font: var(--font-500-15-18-M);
  display: flex;
  gap: 3px;
}

.errorTitle {
  color: var(--error);
}

.placeholder {
  font: var(--font-400-14-18-R);
  margin-right: 21.4px;
  color: var(--text);
}

.placeholderError {
  color: var(--error);
}

.option_choose {
  color: var(--main_black);
}

.list {
  position: absolute;
  top: 55px;
  left: 0;
  width: 100%;
  padding: 0 14px;
  display: flex;
  flex-direction: column;
  background: var(--white);
  box-shadow: 0 0 18px 2px rgba(128, 125, 174, 0.19);
  border-radius: 4px;
  z-index: 2;
}

.hide {
  display: none;
}

.option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  cursor: pointer;
  border-bottom: 1px solid #e8e8e8;
  text-align: start;
  font: var(--font-400-14-18-R);

  &:last-child {
    border-bottom: none;
  }
}

.labelWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.disabledOption {
  color: var(--normal);
}

.gridItem3 {
  width: 661px;
  height: 120px;
}

.headerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 27px 0 14px 0;
}

.characteristics {
  display: flex;
  gap: 57px;
  margin-bottom: 10px;
  > h3 {
    white-space: nowrap;
  }
}

.wrapper {
  width: 100%;
}

.count {
  display: flex;
  align-items: flex-start;
  font: var(--font-500-15-18-M);
  gap: 6px;
}

.entity {
  color: #7d7d7d;
  span {
    display: block;
    width: 54px;
    font: var(--font-400-15-18-R);
    white-space: nowrap;
  }
}

.container {
  min-height: 68px;
  min-width: 183px;
  border-radius: 36px;
  background: #f2f5ff;
  position: relative;
  box-shadow: 0px 0px 20px 2px rgba(120, 120, 120, 0.08);
}

.button {
  position: relative;
  min-height: 68px;
  min-width: 183px;
  display: flex;
  align-items: center;
  padding: 10px 0 10px 11px;
  background: #f2f5ff;
  font: var(--font-600-16-18-Ral-SemiB);
  color: var(--main_black);
  border-radius: 36px;

  &:hover {
    background-color: #938cff;
    color: var(--white);

    .avatar {
      box-shadow: 0 0 0 2px #fff;
    }
  }

  &:active {
    background-color: #675feb;
    color: var(--white);

    .avatar {
      box-shadow: 0 0 0 2px #fff;
    }
  }
}

.avatar {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  box-shadow: 0 0 0 2px #8179fe;
}
.notavatar {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: #e8e8e8;
}

.show {
  position: absolute;
  top: 80px;
  left: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 30px;
  background: var(--white);
  box-shadow: 0 0 20px 2px rgba(120, 120, 120, 0.08);
  border-radius: 20px;
  z-index: 3;
}

.hide {
  display: none;
}

.wrapper {
  margin: 7px 28px 8px 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.name {
  padding-bottom: 3px;
  font: var(--font-600-14-18-Ral-SemiB);
}

.role {
  font: var(--font-400-12-14-R);
}

.personal {
  width: max-content;
  padding-bottom: 10px;
  font: var(--font-600-14-18-Ral-SemiB);
  color: #434343;

  &:hover {
    color: #6860f1;
  }
}

.logout {
  font: var(--font-600-14-18-Ral-SemiB);
  color: #7d7d7d;
  cursor: pointer;
  background: transparent;
}

.grey {
  background-color: #e8e8e8;
  font-size: 0;
  height: 12px;
  width: 100px;
  border-radius: 2px;
  &:first-child {
    width: 86px;
  }
  &:not(:last-child) {
    margin-bottom: 7px;
  }
}

.headerContainer {
  margin: -4px auto 21px auto;
  width: 1340px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headingWrapper {
  display: flex;
  align-items: center;
}

.headerClip {
  font: var(--font-700-18-24-Ral-B);
  color: var(--main_black);
}

.filters {
  display: flex;
  gap: 8px;
}

.separator {
  margin: 0 auto;
  width: 1340px;
  overflow: hidden;
  div {
    margin-bottom: 0;
  }
}

.emptyButton {
  width: 160px;
}

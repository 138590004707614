.img {
  width: 53px;
  height: 53px;
  border-radius: 4px;
  object-fit: cover;
}

.circle {
  border-radius: 50%;
}

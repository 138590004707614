.wrapper {
  width: 320px;
  height: 257px;
  background-color: #fff;
  border-radius: 10px;
}

.titleBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 15px 16px 10px;

  svg {
    width: 26px;
    height: 26px;
    fill: var(--input_background);
    border: 0.5px solid var(--main_black);
    border-radius: 50%;
    rect {
      stroke: var(--input_background);
    }
    path {
      fill: var(--main_black);
    }
  }
}

.titleWrapper {
  position: relative;
  cursor: pointer;
  flex-grow: 1;
}

.input {
  flex-grow: 1;
  color: var(--main_black);
  font: var(--font-500-16-16-M);
  &::placeholder {
    color: var(--placeholder_color);
    font: var(--font-400-16-16-R);
  }
}

.separator {
  width: 100%;
  margin-bottom: 16px;
}

.addButtonWrapper {
  position: relative;
}

.buttonPlusMiddle {
  background-color: transparent;
  font-size: 0;
  line-height: 0;
}

.listBlock {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 0 16px 20px;
  max-width: 310px;
  max-height: 175px;
}

.listItem {
  max-height: 27px;
  padding: 4px 8px 5px;
  background: rgba(140, 90, 240, 0.15);
  border-radius: 3px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  a,
  a:hover {
    font: var(--font-400-14-18-R);
    color: var(--main-black);
    width: 278px;
  }
}

.inputWidth {
  width: 271px;
}

.name {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.contextMenu {
  margin-left: -14px;
}

div {
  scroll-behavior: smooth !important;
}

.listBlockShow {
  max-width: 1200px;
  max-height: 510px;
  display: grid;
  grid-template-columns: repeat(2, 228px);
  border: none;
  overflow: visible;
  column-gap: 45px;
}

.marginLeft {
  margin-left: 16px;
}

.tagName {
  padding: 4px 8px 5px;
  font: var(--font-400-14-18-R);
  background-color: var(--tag_low);
  border-radius: 3px;
  white-space: nowrap;
  overflow: visible;
  text-overflow: ellipsis;
}

.modalTagName {
  padding: 0;
  color: var(--main_black);
  font: var(--font-400-14-18-R);
  background-color: white;
  border: none;
}

.modalWrapper {
  padding: 15px 16px 10px;
}

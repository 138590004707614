.container {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 16px 12px;
  border-radius: 10px;
  border: 1px solid var(--error, #e33d27);
  background: var(--button_delete-bg, #ffeded);
}
